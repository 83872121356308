// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://carismaclassic.com/backend/api',
  image_base_url: 'https://carismaclassic.com/backend/public/uploads',
  languages: [
    'English',
    'Español',
    'Deutsche',
    'Pусский',
  ],
  languages_arr: [
    {
      'id' : 1,
      'code' : 'eng',
      'language' : 'English',
    },
    {
      'id' : 2,
      'code' : 'spa',
      'language' : 'Español',
    },
    {
      'id' : 3,
      'code' : 'ger',
      'language' : 'Deutsche',
    },
    {
      'id' : 4,
      'code' : 'rus',
      'language' : 'Pусский',
    },
  ],
  car_status: [
    {
      'eng' : 'Available',
      'spa' : 'Disponible',
      'ger' : 'Verfügbar',
      'rus' : 'Имеется в наличии',
    },
    {
      'eng' : 'New',
      'spa' : 'Nueva',
      'ger' : 'Neu',
      'rus' : 'Новый',
    },
    {
      'eng' : 'Sold',
      'spa' : 'Vendida',
      'ger' : 'Verkauft',
      'rus' : 'Продано',
    },
    {
      'eng' : 'Reserved',
      'spa' : 'Reservada',
      'ger' : 'Reserviert',
      'rus' : 'Зарезервированный',
    }
  ],
  car_details: [
    {
      'eng' : 'First Matriculation',
      'spa' : 'Primera matriculación',
      'ger' : 'Erste Immatrikulation',
      'rus' : 'Первое зачисление',
    },
    {
      'eng' : 'Consumption',
      'spa' : 'Consumo',
      'ger' : 'Verbrauch',
      'rus' : 'Потребление',
    },
    {
      'eng' : 'Year',
      'spa' : 'Año',
      'ger' : 'Jahr',
      'rus' : 'Год',
    },
    {
      'eng' : 'CO2 emissions',
      'spa' : 'emisiones de CO2',
      'ger' : 'CO2-Emissionen',
      'rus' : 'Выбросы CO2',
    },
    {
      'eng' : 'Measurements (length/width/height)',
      'spa' : 'Medidas (largo / ancho / alto)',
      'ger' : 'Maße (Länge / Breite / Höhe)',
      'rus' : 'Размеры (длина / ширина / высота)',
    },
    {
      'eng' : 'Tires',
      'spa' : 'Neumáticos',
      'ger' : 'Reifen',
      'rus' : 'Шины',
    },
    {
      'eng' : 'Weight',
      'spa' : 'Peso',
      'ger' : 'Gewicht',
      'rus' : 'Масса',
    },
  ],
  technical_details: [
    {
      'eng' : 'Engine',
      'spa' : 'Motor',
      'ger' : 'Motor',
      'rus' : 'Двигатель',
    },
    {
      'eng' : 'Displacement',
      'spa' : 'Desplazamiento',
      'ger' : 'Verschiebung',
      'rus' : 'Смещение',
    },
    {
      'eng' : 'Power',
      'spa' : 'Poder',
      'ger' : 'Leistung',
      'rus' : 'Мощность',
    },
    {
      'eng' : 'Torque',
      'spa' : 'Esfuerzo de torsión',
      'ger' : 'Drehmoment',
      'rus' : 'Крутящий момент',
    },
    {
      'eng' : 'Transmission',
      'spa' : 'Transmisión',
      'ger' : 'Übertragung',
      'rus' : 'Передача инфекции',
    },
    {
      'eng' : 'Acceleration',
      'spa' : 'Aceleración',
      'ger' : 'Beschleunigung',
      'rus' : 'Ускорение',
    },
    {
      'eng' : 'Maximum speed',
      'spa' : 'Velocidad máxima',
      'ger' : 'Maximale Geschwindigkeit',
      'rus' : 'Максимальная скорость',
    },
    
  ],
  individual_details: [
    {
      'eng' : 'VIN',
      'spa' : 'VIN',
      'ger' : 'VIN',
      'rus' : 'VIN',
    },
  ],
  crop_img_url: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
