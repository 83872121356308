import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  environment = environment;
  add_slider_form: FormGroup;
  submitted = false;
  sliders = [];
  fileData: File = null;
  slider_img_id = '';
  slider_img_url:any = ''; 
  selected_language = '';
  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.get_sliders(1);
    this.add_slider('');
  }

  // Function to get list of sliders
  get_sliders(language_id) {
    this.selected_language = language_id;
    this.http.get<any>(environment.base_url+'/get_sliders/'+language_id).subscribe(data => {
      if(data.success) {
        this.sliders = data.data;
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to return update main menu form errors
  get get_errors() { 
    return this.add_slider_form.controls 
  }
  
  // Function to update main menu
  onSubmit() {
    this.submitted = true
    if(this.add_slider_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/save_slider', {
      title: this.add_slider_form.get('title').value,
      id: this.add_slider_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.add_slider_form.reset();
        this.get_sliders(1);
        //this.add_slider(data.data)
        //this.get_menus(this.selected_language_id);
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //  Function to return array of title fields
  add_slider(slider_id) {
    if(slider_id != '') { 
      this.slider_img_id = slider_id;
      this.http.get<any>(environment.base_url+'/get_slider/'+slider_id).subscribe(data => {
        if(data.success) {
          this.slider_img_url = environment.image_base_url+'/car_slider_images/'+data.data.id+'/'+data.data.img;
          if(data.data.slider_image_translations.length > 0) {
            var title_languages_arr = [];
            data.data.slider_image_translations.forEach(translation => {
              let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], title1:[translation.title1, Validators.required], title2:[translation.title2, Validators.required], description:[translation.description, Validators.required]});
              title_languages_arr.push(title);
            });
            this.add_slider_form = this.fb.group({
              id: [slider_id],
              title: this.fb.array(title_languages_arr),
            })
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            this.toastr.error("Slider translations not found.", "")    
          }
        } 
        else {
          this.spinner.hide();
          this.toastr.error(data.msg.text, "")  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    else {
      var title_languages_arr = [];
      environment.languages_arr.forEach(translation => {
        let title = this.fb.group({language_code:[translation.code, Validators.required], language_id:[translation.id, Validators.required], title1:['', Validators.required], title2:['', Validators.required], description:['', Validators.required]});
        title_languages_arr.push(title);
      });
      this.add_slider_form = this.fb.group({
        id: [],
        title: this.fb.array(title_languages_arr),
      })
    }
  }

  //Function to upload slider image
  upload_slider_image(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Please select image file.", "");
      return
    }
    if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png') {
      if(this.fileData.size > 5000000) {
        this.toastr.error("Image file size should not be greater than 5MB.", "");  
        return;
      }
      this.spinner.show();
      const formData = new FormData();
      formData.append('file', this.fileData, this.slider_img_id+'.'+mimeType.substr(6));
      this.http.post<any>(environment.base_url+'/upload_slider_image', formData).subscribe(data => {
        if(data.success) {
          //console.log(data)
          var reader = new FileReader();      
          reader.readAsDataURL(this.fileData); 
          reader.onload = (_event) => { 
            this.slider_img_url = reader.result; 
          }
          this.get_sliders(this.selected_language);
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")  
    }
    else {
      this.toastr.error("Image should be PNG|JPEG|JPG.", "");
      return
    }
  }

  //  Function to return array of title fields
  get titleLanguagesArr() {
    return this.add_slider_form.get('title') as FormArray;
  }
}
