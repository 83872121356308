import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.css']
})
export class BlogCategoriesComponent implements OnInit {

  environment = environment;
  submitted = false;
  selected_language = 1;
  blog_category_form: FormGroup;
  category_id = '';
  categories_arr = [];
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.add_category('');
    this.get_categories(this.selected_language);
  }
  
  get_categories(selected_language) {
    this.selected_language = selected_language;
    this.http.get<any>(environment.base_url+'/get_categories/'+selected_language).subscribe(data => {
      if(data.success) {
        this.categories_arr = data.data;
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }

  //Function to add blog category
  add_category(category_id) {
    if(category_id != '' && category_id != undefined) { 
      this.http.get<any>(environment.base_url+'/get_category/'+category_id).subscribe(data => {
        if(data.success) {
          if(data.data.category_translations.length > 0) {
            var title_languages_arr = [];
            data.data.category_translations.forEach(translation => {
              let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], title:[translation.title, Validators.required]});
              title_languages_arr.push(title);
            });
            this.blog_category_form = this.fb.group({
              id: [category_id],
              title: this.fb.array(title_languages_arr),
            })
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            this.toastr.error("Testimonial translations not found.", "")    
          }
        } 
        else {
          this.spinner.hide();
          this.toastr.error(data.msg.text, "")  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    else {
      var title_languages_arr = [];
      environment.languages_arr.forEach(translation => {
        let title = this.fb.group({language_code:[translation.code, Validators.required], language_id:[translation.id, Validators.required], title:['', Validators.required]});
        title_languages_arr.push(title);
      });
      this.blog_category_form = this.fb.group({
        id: [],
        title: this.fb.array(title_languages_arr),
      })
      this.spinner.hide();
    }
  }

  //Function to save blog category
  save_category() {
    this.submitted = true
    if(this.blog_category_form.invalid) {
      return 
    }
    this.http.post<any>(environment.base_url+'/save_category', {
      title: this.blog_category_form.get('title').value,
      id: this.blog_category_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.blog_category_form.reset(); 
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
        this.add_category('');
        this.get_categories(this.selected_language);
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to delete category
  delete_category(category_id) {
    if(confirm('Are you sure to delete this category?0')) {
      this.http.post<any>(environment.base_url+'/delete_category', {
        id: category_id,
      }).subscribe(data => {
        if(data.success) {
          this.get_categories(this.selected_language);
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    
  }

  //  Function to return array of why us button fields
  get titleLanguagesArr() {
    return this.blog_category_form.get('title') as FormArray;
  }

}
