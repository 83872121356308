import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  environment = environment;
  testimonials_arr = [];
  language_id = '';
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.get_testimonials(1);
  }

  // Function to get list of all testimonials
  get_testimonials(language_id) {
    this.language_id = language_id;
    this.http.get<any>(environment.base_url+'/get_testimonials/'+language_id).subscribe(data => {
      if(data.success) {
        this.testimonials_arr = data.data;
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to delete testimonial
  delete_testimonial(testimonial_id) {
    if(confirm('Are you sure to delete this testimonial?')) {
      if(testimonial_id != '' && testimonial_id != undefined && testimonial_id != null) {
        this.http.post<any>(environment.base_url+'/delete_testimonial', {
          id: testimonial_id
        }).subscribe(data => {
          if(data.success) {
            this.toastr.success(data.msg.text, "")
            this.get_testimonials(this.language_id);  
          } 
          else {
            this.spinner.hide();
            this.toastr.error(data.msg.text, "")  
          }
        }),
        (error) => this.toastr.error(error.message, "")    
      }
      else {
        this.toastr.error("Testimonial id is required to perform this action.", "");  
      }    
    }
  }

}
