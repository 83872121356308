import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  environment = environment;
  save_video_form: FormGroup;
  translation_form: FormGroup;
  submitted = false;
  translation_form_submitted = false;
  sliders = [];
  slider_img_id = '';
  slider_img_url:any = ''; 
  selected_language = '';
  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.http.get<any>(environment.base_url+'/get_investor_page_content').subscribe(data => {
      if(data.success) {
        this.save_video_form = this.fb.group({
          id: [data.data.id, Validators.required],
          youtube_video_url: [data.data.youtube_video_url, Validators.required],
        });

        if(data.data.investor_translations.length > 0) {
          var title_languages_arr = [];
          data.data.investor_translations.forEach(translation => {
            let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], title1:[translation.title1, Validators.required], title2:[translation.title2, Validators.required], description:[translation.description, Validators.required], btn_title:[translation.btn_title, Validators.required]});
            title_languages_arr.push(title);
          });
          this.translation_form = this.fb.group({
            investor_id: [data.data.id],
            title: this.fb.array(title_languages_arr),
          })
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.toastr.error("Slider translations not found.", "")    
        }
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to return youtube video errors
  get get_errors() { 
    return this.save_video_form.controls 
  }


  // Function to save youtube video
  save_video() {
    this.submitted = true
    if(this.save_video_form.invalid) {
      return 
    }
    var video_id = this.youtube_parser(this.save_video_form.get('youtube_video_url').value);
    this.http.post<any>(environment.base_url+'/save_youtube_video', {
      youtube_video_id: video_id,
      youtube_video_url: this.save_video_form.get('youtube_video_url').value,
      id: this.save_video_form.get('id').value
    }).subscribe(data => {
      if(data.success) {
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to extract youtube video id
  youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  // Function to save investor page content's translation
  save_translation() {
    this.translation_form_submitted = true
    if(this.translation_form.invalid) {
      return 
    }
    this.http.post<any>(environment.base_url+'/save_investor_translations', {
      title: this.translation_form.get('title').value,
      investor_id: this.translation_form.get('investor_id').value,
    }).subscribe(data => {
      if(data.success) {
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //  Function to return array of title fields
  get titleLanguagesArr() {
    return this.translation_form.get('title') as FormArray;
  }

}
