<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Add New Testimonial</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#!">Testimonial</a></li>
                            <li class="breadcrumb-item"><a href="#!">Add New Testimonial</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <form *ngIf="section_title_form" [formGroup]="section_title_form" (ngSubmit)="save_section_title()">
                        <input type="hidden" formControlName="id">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-section-tab-{{language.id}}" data-toggle="pill" href="#pills-section-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of testimonialSectionTitleArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-section-{{item.value.language_id}}" role="tabpanel">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="hidden" formControlName="language_id">
                                                        <input type="hidden" formControlName="language_code">
                                                        <label for="exampleInputEmail1">Testiomonial Section Title</label>
                                                        <input type="text" formControlName="title" class="form-control"  aria-describedby="emailHelp" placeholder="Title">
                                                        <label *ngIf="section_title_form_submitted && item.controls.title.errors && item.controls.title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-1">
                                                    <button type="submit" class="btn  btn-primary">Save</button>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                    <button type="submit" class="btn  btn-primary">Translate To All</button>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card">
                    <div class="card-body">
                        <form *ngIf="testimonial_form" [formGroup]="testimonial_form" (ngSubmit)="onSubmit()">
                            <input type="hidden" formControlName="id">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="hidden" formControlName="language_id">
                                                        <input type="hidden" formControlName="language_code">
                                                        <input type="hidden" formControlName="testimonial_section_id">
                                                        <label for="exampleInputEmail1">Author Name</label>
                                                        <input type="text" class="form-control" formControlName="author_name"  aria-describedby="emailHelp" placeholder="Author Name">
                                                        <label *ngIf="testimonial_form_submitted && item.controls.author_name.errors && item.controls.author_name.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Description</label>
                                                        <textarea class="form-control" formControlName="description" placeholder="Description"></textarea>
                                                        <label *ngIf="testimonial_form_submitted && item.controls.description.errors && item.controls.description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="testimonial_id != '' && testimonial_id != undefined">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Author Image</label>
                                                <input type="file" class="form-control"  aria-describedby="emailHelp" placeholder="Upload Image" (change)="upload_image($event)" id="inputGroupFile04">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">&nbsp;</label><br>
                                                <img [src]="author_img_url" width="50px" height="50px">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-1">
                                            <button type="submit" class="btn  btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Input group -->
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>