import { Component } from '@angular/core';
import {Router} from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'my-app';
  constructor(
    public router: Router, 
    private authService: AuthGuardService
  ) { 
    //if (authService.isLoggedIn()) {
      // if(router.url === '/') {
      //   router.navigate(['add_car']);
      // }
    //}
  }
}
