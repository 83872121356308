<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Testimonial List</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#!">Testimonial</a></li>
                            <li class="breadcrumb-item"><a href="#!">Testimonial List</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row">

            <div class="col-lg-12">
                <div class="card user-profile-list">
                    <div class="card-body">
                        <div class="">
                            <div class="" *ngIf="environment.languages_arr.length > 0">
                                <ul class="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                    <select class="form-control" style="width:200px" (change)="get_testimonials($event.target.value)">
                                        <option [value]="language.id" *ngFor="let language of environment.languages_arr">
                                            {{language.language}}
                                        </option>
                                    </select>
                                </ul>
                                <div class="tab-content" id="pills-tabContent1">
                                    <div class="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                        <div class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Author Name</th>
                                                        <th>Description</th>
                                                        <th>Author Image</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="testimonials_arr.length > 0;else testimonial_not_dound">
                                                    <tr *ngFor="let testimonial of testimonials_arr">
                                                        <td>{{testimonial.testimonial_translation.author_name}}</td>
                                                        <td>{{testimonial.testimonial_translation.description}}</td>
                                                        <td><img *ngIf="testimonial.img != null" [src]="environment.image_base_url+'/testimonials/'+testimonial.id+'/'+testimonial.img" width="100px"></td>
                                                        <td>
                                                            <span class="badge badge-light-success">Active</span>
                                                            <div class="overlay-edit">
                                                                <a type="button" class="btn btn-icon btn-success" [routerLink]="['/update_testimonial/'+testimonial.id]"><i class="feather icon-edit"></i></a>
                                                                <button type="button" (click)="delete_testimonial(testimonial.id)" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <ng-template #testimonial_not_dound>
                                                    <div style="text-align:centerl;font-size:18px;">
                                                        No testimonial found.
                                                    </div>
                                                </ng-template>
                                                <tfoot>
                                                    <tr>
                                                        <th>Author Name</th>
                                                        <th>Description</th>
                                                        <th>Author Image</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>