import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.css']
})
export class LogosComponent implements OnInit {

  environment = environment;
  fileData: File = null;
  header_img_url:any = '';
  footer_img_url:any = ''; 
  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.get_logos();
  }

  // Function to get header and footer logo
  get_logos() { 
    this.http.get<any>(environment.base_url+'/get_logos').subscribe(data => {
      if(data.success) {
        this.header_img_url = environment.image_base_url+'/'+data.data[0].type+'/'+data.data[0].img;
        this.footer_img_url = environment.image_base_url+'/'+data.data[1].type+'/'+data.data[1].img;
        this.spinner.hide();
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }
  

  //Function to upload header logo
  upload_logo(fileInput: any, type) {
    if(type != '' && type != undefined && type != null) {
      this.fileData = <File>fileInput.target.files[0];
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        this.toastr.error("Please select image file.", "");
        return
      }
      
      if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png' || mimeType == 'image/svg+xml') {
        if(this.fileData.size > 5000000) {
          this.toastr.error("Image file size should not be greater than 5MB.", "");  
          return;
        }
        const formData = new FormData();
        if(mimeType == 'image/svg+xml') {
          mimeType = mimeType.substr(6, 3);
        }
        else {
          mimeType = mimeType.substr(6);
        }
        if(type == 'header') {
          formData.append('file', this.fileData, 'header.'+mimeType);
        }
        else if(type == 'footer'){
          formData.append('file', this.fileData, 'footer.'+mimeType);
        } 
        else {
          this.toastr.error("Image type is invalid.", "");
          return  
        }
        this.http.post<any>(environment.base_url+'/upload_logo', formData).subscribe(data => {
          if(data.success) {
            //console.log(data)
            var reader = new FileReader();      
            reader.readAsDataURL(this.fileData); 
            reader.onload = (_event) => { 
              this.get_logos();
            }
            this.spinner.hide();
            this.toastr.success(data.msg.text, "");
          } 
          else {
            for(var i in data.msg) {
              this.spinner.hide();
              this.toastr.error(data.msg[i], "");
            }  
          }
        }),
        (error) => this.toastr.error(error.message, "")  
      }
      else {
        this.toastr.error("Image should be PNG|JPEG|JPG.", "");
        return
      }
    }
    else {
      this.toastr.error("Parameter missing.", "");
    }
  }

}
