import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { NgxSpinnerService } from "ngx-spinner";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CdkDragDrop, moveItemInArray,transferArrayItem} from '@angular/cdk/drag-drop';
import {SamDragAndDropGridModule} from '@sam-1994/ngx-drag-and-drop-grid';

@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',  
  styleUrls: ['./add-car.component.css']
})
export class AddCarComponent implements OnInit {
  public Editor = ClassicEditor;
  add_car_form: FormGroup;
  environment = environment;
  brands_list_arr:any = []
  car_models_list_arr: any = []
  submitted = false
  car_id:any = '';
  image_base_url = environment.image_base_url
  car_data:any = [];

  fileData: File = null;
  car_feature_img_url:any = null;
  slug ='';

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private route: ActivatedRoute, 
    private router: Router,
    private spinner: NgxSpinnerService
  ) { 
    
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.car_id = params['id'];
    });
    if(this.car_id != undefined) {
      this.http.get<any>(environment.base_url+'/get_brands/0').subscribe(data => {
        if(data.success) {
          this.brands_list_arr = data.data.brands_list
          this.car_models_list_arr = data.data.car_models_list 
          this.http.get<any>(environment.base_url+'/get_car/'+this.car_id).subscribe(data => {
  
            if(data.success) {
              //console.log(data.data);
              this.car_data = data.data
              if(data.data.car_feature_image != null) {
                this.car_feature_img_url = environment.image_base_url+'/car_feature_images/'+this.car_id+'/'+data.data.car_feature_image.img_name;
              }
              if(data.data.car_details.length > 0) {
                var eng_car_details_arr = []
                var spa_car_details_arr = []
                var ger_car_details_arr = []
                var rus_car_details_arr = []
                for(let car_detail of data.data.car_details) {
                  let eng_car_detail_data = this.fb.group({label:[car_detail.eng_label, Validators.required], value:[car_detail.eng_value, Validators.required]});   
                  let spa_car_detail_data = this.fb.group({label:[car_detail.spa_label, Validators.required], value:[car_detail.spa_value, Validators.required]});
                  let ger_car_detail_data = this.fb.group({label:[car_detail.ger_label, Validators.required], value:[car_detail.ger_value, Validators.required]});   
                  let rus_car_detail_data = this.fb.group({label:[car_detail.rus_label, Validators.required], value:[car_detail.rus_value, Validators.required]});      
                  eng_car_details_arr.push(eng_car_detail_data);
                  spa_car_details_arr.push(spa_car_detail_data);
                  ger_car_details_arr.push(ger_car_detail_data);
                  rus_car_details_arr.push(rus_car_detail_data);
                }

                var eng_car_tech_details_arr = []
                var spa_car_tech_details_arr = []
                var ger_car_tech_details_arr = []
                var rus_car_tech_details_arr = []
                for(let car_tech_detail of data.data.car_tech_details) {
                  let eng_car_tech_detail_data = this.fb.group({label:[car_tech_detail.eng_label, Validators.required], value:[car_tech_detail.eng_value, Validators.required]});   
                  let spa_car_tech_detail_data = this.fb.group({label:[car_tech_detail.spa_label, Validators.required], value:[car_tech_detail.spa_value, Validators.required]});
                  let ger_car_tech_detail_data = this.fb.group({label:[car_tech_detail.ger_label, Validators.required], value:[car_tech_detail.ger_value, Validators.required]});   
                  let rus_car_tech_detail_data = this.fb.group({label:[car_tech_detail.rus_label, Validators.required], value:[car_tech_detail.rus_value, Validators.required]});      
                  eng_car_tech_details_arr.push(eng_car_tech_detail_data);
                  spa_car_tech_details_arr.push(spa_car_tech_detail_data);
                  ger_car_tech_details_arr.push(ger_car_tech_detail_data);
                  rus_car_tech_details_arr.push(rus_car_tech_detail_data);
                }

                var eng_car_config_arr = []
                var spa_car_config_arr = []
                var ger_car_config_arr = []
                var rus_car_config_arr = []
                for(let car_config of data.data.car_individual_config_details) {
                  let eng_car_config_data = this.fb.group({label:[car_config.eng_label, Validators.required], value:[car_config.eng_value, Validators.required]});   
                  let spa_car_config_data = this.fb.group({label:[car_config.spa_label, Validators.required], value:[car_config.spa_value, Validators.required]});
                  let ger_car_config_data = this.fb.group({label:[car_config.ger_label, Validators.required], value:[car_config.ger_value, Validators.required]});   
                  let rus_car_config_data = this.fb.group({label:[car_config.rus_label, Validators.required], value:[car_config.rus_value, Validators.required]});      
                  eng_car_config_arr.push(eng_car_config_data);
                  spa_car_config_arr.push(spa_car_config_data);
                  ger_car_config_arr.push(ger_car_config_data);
                  rus_car_config_arr.push(rus_car_config_data);
                }
                
              }

              this.add_car_form = this.fb.group({
                id: [this.car_id],
                slug:[data.data.slug, Validators.required],
                car_brand_id: [data.data.car_brand_id, Validators.required],
                car_model_id: [data.data.car_model_id, Validators.required],
                price: [data.data.price, Validators.required],
                average: [data.data.average, Validators.required],
                year: [data.data.year, Validators.required],
                fuel_type: [data.data.fuel_type, Validators.required],
                car_status: [data.data.car_status, Validators.required],
                km: [data.data.km, Validators.required],
                eng_title: [data.data.eng_title, Validators.required],
                spa_title: [data.data.spa_title, Validators.required],
                ger_title: [data.data.ger_title, Validators.required],
                rus_title: [data.data.rus_title, Validators.required],
                short_eng_description: [data.data.short_eng_description, Validators.required],
                short_spa_description: [data.data.short_spa_description, Validators.required],
                short_ger_description: [data.data.short_ger_description, Validators.required],
                short_rus_description: [data.data.short_rus_description, Validators.required],
                eng_description: [data.data.eng_description, Validators.required],
                spa_description: [data.data.spa_description, Validators.required],
                ger_description: [data.data.ger_description, Validators.required],
                rus_description: [data.data.rus_description, Validators.required],
                eng_car_details: this.fb.array(eng_car_details_arr),
                spa_car_details: this.fb.array(spa_car_details_arr),
                ger_car_details: this.fb.array(ger_car_details_arr),
                rus_car_details: this.fb.array(rus_car_details_arr),
                eng_technical_details: this.fb.array(eng_car_tech_details_arr),
                spa_technical_details: this.fb.array(spa_car_tech_details_arr),
                ger_technical_details: this.fb.array(ger_car_tech_details_arr),
                rus_technical_details: this.fb.array(rus_car_tech_details_arr),
                eng_individual_config: this.fb.array(eng_car_config_arr),
                spa_individual_config: this.fb.array(spa_car_config_arr),
                ger_individual_config: this.fb.array(ger_car_config_arr),
                rus_individual_config: this.fb.array(rus_car_config_arr),
              })
                
            this.add_car_form['slug'] = data.data.slug
              this.spinner.hide();
            } 
            else {
              this.toastr.error(data.msg.text, "")  
            }
          }),
          (error) => this.toastr.error(error.message, "")
        } 
        else {
          this.toastr.error(data.msg.text, "")  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    else {
      this.http.get<any>(environment.base_url+'/get_brands/0').subscribe(data => {
        if(data.success) {
          this.brands_list_arr = data.data.brands_list
          this.car_models_list_arr = data.data.car_models_list  
          var eng_car_details_arr = []
          var spa_car_details_arr = []
          var ger_car_details_arr = []
          var rus_car_details_arr = []
          for(let car_detail of environment.car_details) {
            let eng_car_detail_data = this.fb.group({label:[car_detail.eng, Validators.required], value:['', Validators.required]});   
            let spa_car_detail_data = this.fb.group({label:[car_detail.spa, Validators.required], value:['', Validators.required]});
            let ger_car_detail_data = this.fb.group({label:[car_detail.ger, Validators.required], value:['', Validators.required]});   
            let rus_car_detail_data = this.fb.group({label:[car_detail.rus, Validators.required], value:['', Validators.required]});      
            eng_car_details_arr.push(eng_car_detail_data);
            spa_car_details_arr.push(spa_car_detail_data);
            ger_car_details_arr.push(ger_car_detail_data);
            rus_car_details_arr.push(rus_car_detail_data);
          }

          var eng_car_tech_details_arr = []
          var spa_car_tech_details_arr = []
          var ger_car_tech_details_arr = []
          var rus_car_tech_details_arr = []
          for(let car_tech_detail of environment.technical_details) {
            let eng_car_tech_detail_data = this.fb.group({label:[car_tech_detail.eng, Validators.required], value:['', Validators.required]});   
            let spa_car_tech_detail_data = this.fb.group({label:[car_tech_detail.spa, Validators.required], value:['', Validators.required]});
            let ger_car_tech_detail_data = this.fb.group({label:[car_tech_detail.ger, Validators.required], value:['', Validators.required]});   
            let rus_car_tech_detail_data = this.fb.group({label:[car_tech_detail.rus, Validators.required], value:['', Validators.required]});      
            eng_car_tech_details_arr.push(eng_car_tech_detail_data);
            spa_car_tech_details_arr.push(spa_car_tech_detail_data);
            ger_car_tech_details_arr.push(ger_car_tech_detail_data);
            rus_car_tech_details_arr.push(rus_car_tech_detail_data);
          }

          var eng_car_config_arr = []
          var spa_car_config_arr = []
          var ger_car_config_arr = []
          var rus_car_config_arr = []
          for(let car_config of environment.individual_details) {
            let eng_car_config_data = this.fb.group({label:[car_config.eng, Validators.required], value:['', Validators.required]});   
            let spa_car_config_data = this.fb.group({label:[car_config.spa, Validators.required], value:['', Validators.required]});
            let ger_car_config_data = this.fb.group({label:[car_config.ger, Validators.required], value:['', Validators.required]});   
            let rus_car_config_data = this.fb.group({label:[car_config.rus, Validators.required], value:['', Validators.required]});      
            eng_car_config_arr.push(eng_car_config_data);
            spa_car_config_arr.push(spa_car_config_data);
            ger_car_config_arr.push(ger_car_config_data);
            rus_car_config_arr.push(rus_car_config_data);
          }
          
          this.add_car_form = this.fb.group({
            id: [''],
            slug: ['', Validators.required],
            car_brand_id: ['', Validators.required],
            car_model_id: ['', Validators.required],
            price: ['', Validators.required],
            average: ['', Validators.required],
            year: ['', Validators.required],
            fuel_type: ['', Validators.required],
            car_status: ['', Validators.required],
            km: ['', Validators.required],
            eng_title: ['', Validators.required],
            spa_title: ['', Validators.required],
            ger_title: ['', Validators.required],
            rus_title: ['', Validators.required],
            short_eng_description: ['', Validators.required],
            short_spa_description: ['', Validators.required],
            short_ger_description: ['', Validators.required],
            short_rus_description: ['', Validators.required],
            eng_description: ['', Validators.required],
            spa_description: ['', Validators.required],
            ger_description: ['', Validators.required],
            rus_description: ['', Validators.required],
            eng_car_details: this.fb.array(eng_car_details_arr),
            spa_car_details: this.fb.array(spa_car_details_arr),
            ger_car_details: this.fb.array(ger_car_details_arr),
            rus_car_details: this.fb.array(rus_car_details_arr),
            eng_technical_details: this.fb.array(eng_car_tech_details_arr),
            spa_technical_details: this.fb.array(spa_car_tech_details_arr),
            ger_technical_details: this.fb.array(ger_car_tech_details_arr),
            rus_technical_details: this.fb.array(rus_car_tech_details_arr),
            eng_individual_config: this.fb.array(eng_car_config_arr),
            spa_individual_config: this.fb.array(spa_car_config_arr),
            ger_individual_config: this.fb.array(ger_car_config_arr),
            rus_individual_config: this.fb.array(rus_car_config_arr),
          })
          this.spinner.hide();
          // this.http.get<any>(environment.base_url+'/get_pages').subscribe(data => {
          //   if(data.success) {
              
          //   } 
          //   else {
          //     this.toastr.error(data.msg.text, "")  
          //   }
          // }),
          // (error) => this.toastr.error(error.message, "")
        } 
        else {
          this.toastr.error(data.msg.text, "")  
          this.spinner.hide();
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
  }

  // Function to return add car form errors
  get get_errors() { 
    return this.add_car_form.controls 
  }

  // Function to submit car details on server
  onSubmit() {
    //console.log(this.add_car_form)
    this.submitted = true
    if(this.add_car_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/add_car', {
      id: this.add_car_form.get('id').value,
      slug: this.add_car_form.get('slug').value,
      car_brand_id: this.add_car_form.get('car_brand_id').value,
      car_model_id: this.add_car_form.get('car_model_id').value,
      price: this.add_car_form.get('price').value,
      average: this.add_car_form.get('average').value,
      year: this.add_car_form.get('year').value,
      fuel_type: this.add_car_form.get('fuel_type').value,
      car_status: this.add_car_form.get('car_status').value,
      km: this.add_car_form.get('km').value,
      eng_title: this.add_car_form.get('eng_title').value,
      spa_title: this.add_car_form.get('spa_title').value,
      ger_title: this.add_car_form.get('ger_title').value,
      rus_title: this.add_car_form.get('rus_title').value,
      short_eng_description: this.add_car_form.get('short_eng_description').value,
      short_spa_description: this.add_car_form.get('short_spa_description').value,
      short_ger_description: this.add_car_form.get('short_ger_description').value,
      short_rus_description: this.add_car_form.get('short_rus_description').value,
      eng_description: this.add_car_form.get('eng_description').value,
      spa_description: this.add_car_form.get('spa_description').value,
      ger_description: this.add_car_form.get('ger_description').value,
      rus_description: this.add_car_form.get('rus_description').value,
      eng_car_details: this.add_car_form.get('eng_car_details').value,
      spa_car_details: this.add_car_form.get('spa_car_details').value,
      ger_car_details: this.add_car_form.get('ger_car_details').value,
      rus_car_details: this.add_car_form.get('rus_car_details').value,
      eng_technical_details: this.add_car_form.get('eng_technical_details').value,
      spa_technical_details: this.add_car_form.get('spa_technical_details').value,
      ger_technical_details: this.add_car_form.get('ger_technical_details').value,
      rus_technical_details: this.add_car_form.get('rus_technical_details').value,
      eng_individual_config: this.add_car_form.get('eng_individual_config').value,
      spa_individual_config: this.add_car_form.get('spa_individual_config').value,
      ger_individual_config: this.add_car_form.get('ger_individual_config').value,
      rus_individual_config: this.add_car_form.get('rus_individual_config').value,
    }).subscribe(data => {
      if(data.success) {
        if(this.car_id == undefined) {
          this.router.navigateByUrl('/cars/'+data.data.id);
          this.add_car_form.reset();
        }
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.toastr.error(data.msg[i], "");
          this.spinner.hide();
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }

  //Function to upload car feature image
  upload_feature_image(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Please select image file.", "");
      return
    }
    if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png') {
      if(this.fileData.size > 5000000) {
        this.toastr.error("Image file size should not be greater than 5MB.", "");  
        return;
      }
      this.spinner.show();
      const formData = new FormData();
      formData.append('file', this.fileData, this.car_id+'.'+mimeType.substr(6));
      this.http.post<any>(environment.base_url+'/upload_feature_image', formData).subscribe(data => {
        if(data.success) {
          //console.log(data)
          var reader = new FileReader();      
          reader.readAsDataURL(this.fileData); 
          reader.onload = (_event) => { 
            this.car_feature_img_url = reader.result; 
          }
          this.toastr.success(data.msg.text, "");
          this.spinner.hide();
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")  
    }
    else {
      this.toastr.error("Image should be PNG|JPEG|JPG.", "");
      return
    }
  }

  //Function to upload car feature image
  upload_car_images(fileInput: any) {
    this.fileData = <File>fileInput.target.files;
    let images_count:any = <File>fileInput.target.files.length;
    let mimeType:any;
    const formData = new FormData();
    for(let i=0; i < images_count; i++) {
      mimeType = this.fileData[i].type;
      //console.log(mimeType);
      if (mimeType.match(/image\/*/) == null) {
        this.toastr.error("Please select image file.", "");
        return
      }

      if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png') {
        if(this.fileData[i].size > 5000000) {
          this.toastr.error("Image file size should not be greater than 5MB.", "");  
          return;
        }
        formData.append('file_'+i, this.fileData[i], this.car_id+'.'+mimeType.substr(6));
      }
      else {
        this.toastr.error("Image should be PNG|JPEG|JPG.", "");
        return
      }
      
    }
    this.spinner.show();
    // var reader = new FileReader();      
    // reader.readAsDataURL(this.fileData); 
    // reader.onload = (_event) => { 
    //   this.car_feature_img_url = reader.result; 
    // }
    this.http.post<any>(environment.base_url+'/upload_car_images', formData).subscribe(data => {
      if(data.success) {
        //console.log(data)
        this.get_car_images();
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }

  get_brand_models(brand_id) {
    this.spinner.show();
    this.http.get<any>(environment.base_url+'/get_brands/'+brand_id).subscribe(data => {
      if(data.success) {
        this.car_models_list_arr = data.data.car_models_list   
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }
  
  delete_img(img_id) {
    if(confirm('Are you sure to delete this image?')) {
      this.spinner.show();
      this.http.post<any>(environment.base_url+'/delete_img', {
        img_id: img_id
      }
      ).subscribe(data => {
        if(data.success) {
          //console.log(data)
          this.get_car_images();
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "") 
    } 
  }
  
  get_car_images() {
    this.spinner.show();
    this.http.get<any>(environment.base_url+'/get_car_images/'+this.car_id).subscribe(data => {
      if(data.success) {
        //this.car_models_list_arr = data.data.car_models_list  
        this.car_data.car_images = data.data 
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")    
  }

  get englishCarDetails() {
    return this.add_car_form.get('eng_car_details') as FormArray;
  }
  
  get spaCarDetails() {
    return this.add_car_form.get('spa_car_details') as FormArray;
  }
  
  get gerCarDetails() {
    return this.add_car_form.get('ger_car_details') as FormArray;
  }
  
  get rusCarDetails() {
    return this.add_car_form.get('rus_car_details') as FormArray;
  }
  
  get engTechDetails() {
    return this.add_car_form.get('eng_technical_details') as FormArray;
  }
  
  get spaTechDetails() {
    return this.add_car_form.get('spa_technical_details') as FormArray;
  }
  
  get gerTechDetails() {
    return this.add_car_form.get('ger_technical_details') as FormArray;
  }
  
  get rusTechDetails() {
    return this.add_car_form.get('rus_technical_details') as FormArray;
  }
  
  get engIndividualConfig() {
    return this.add_car_form.get('eng_individual_config') as FormArray;
  }
  
  get spaIndividualConfig() {
    return this.add_car_form.get('ger_individual_config') as FormArray;
  }
  
  get gerIndividualConfig() {
    return this.add_car_form.get('spa_individual_config') as FormArray;
  }
  
  get rusIndividualConfig() {
    return this.add_car_form.get('rus_individual_config') as FormArray;
  }


  // Function to add dynamic fields
  add_field(type) {
    if(type == 'eng_car_details') {
      this.englishCarDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.spaCarDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.gerCarDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]})); 
      this.rusCarDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
    }
    else if(type == 'eng_technical_details') {
      this.engTechDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.spaTechDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.gerTechDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.rusTechDetails.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
    }
    else if(type == 'eng_individual_config') {
      this.engIndividualConfig.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.spaIndividualConfig.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.gerIndividualConfig.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
      this.rusIndividualConfig.push(this.fb.group({label:['', Validators.required], value:['', Validators.required]}));
    }
  }

  // Function to remove dynamic fields
  remove_field(index, type) {
    if(type == 'eng_car_details') {
      this.englishCarDetails.removeAt(index);
      this.spaCarDetails.removeAt(index);
      this.gerCarDetails.removeAt(index);
      this.rusCarDetails.removeAt(index);
    }
    else if(type == 'eng_technical_details') {
      this.engTechDetails.removeAt(index);
      this.spaTechDetails.removeAt(index);
      this.gerTechDetails.removeAt(index);
      this.rusTechDetails.removeAt(index);
    }
    else if(type == 'eng_individual_config') {
      this.engIndividualConfig.removeAt(index);
      this.spaIndividualConfig.removeAt(index);
      this.gerIndividualConfig.removeAt(index);
      this.rusIndividualConfig.removeAt(index);
    }
  }
 
 convertToSlug(str:string){
    
   //replace all special characters | symbols with a space
    str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
    
    // trim spaces at start and end of string
    str = str.replace(/^\s+|\s+$/gm,'');
    
    // replace space with dash/hyphen
    str = str.replace(/\s+/g, '-');
    this.add_car_form['slug'] = str
    this.slug = str
    //return str;
 }


 drop(event: CdkDragDrop<string[]>) {
     if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data, event.previousIndex, 
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
   }
timePeriods =   this.car_data.car_images  ;

  itemsTable: Array<string[]>;
getItemsTable(rowLayout: Element): string[][] {
    if (this.itemsTable) {
      return this.itemsTable;
    } 
    // calculate column size per row
    const { width } = rowLayout.getBoundingClientRect();
    const boxWidth = Math.round(width * .33); // 33% as defined in css
    const columnSize = Math.round(width / boxWidth);

    // calculate row size: items length / column size
    // add 0.5: round up so that last element is shown in next row
    const rowSize = Math.round(this.car_data.car_images.length / columnSize + .5);

    // create table rows
    const copy = [...this.car_data.car_images];
    this.itemsTable = Array(rowSize)
      .fill("")
      .map(
        _ =>
          Array(columnSize) // always fills to end of column size, therefore...
            .fill("")
            .map(_ => copy.shift())
            .filter(item => !!item) // ... we need to remove empty items
      );
       
    return this.itemsTable;
  }

  reorderDroppedItem(event: CdkDragDrop<number[]>) {
    // clone table, since it needs to be re-initialized after dropping
    let copyTableRows = this.itemsTable.map(_ => _.map(_ => _));

    // drop item
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    // update items after drop
    this.timePeriods = this.itemsTable.reduce((previous, current) =>
      previous.concat(current)
    );

    // re-initialize table
    let index = 0;
    this.itemsTable = copyTableRows.map(row =>
      row.map(_ => this.timePeriods[index++])
    );

     this.http.post<any>(environment.base_url+'/order_car_gallery',this.timePeriods).subscribe(data => {
      if(data.success) {
        
        this.spinner.hide();
        //console.log(data)
      } 
      else {
        this.spinner.hide();
        for(var i in data.msg) {
           this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => { this.toastr.error(error.message, "");  this.spinner.hide();}

    
  }



}
