import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user_details:any = [];
  constructor(private router: Router) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('userDetails'));
    this.user_details = user['data'];
  }

  logout() {
    localStorage.removeItem('userDetails');
    this.router.navigate(['']);
  }
}
