<!DOCTYPE html>
<html lang="en">


<!-- Mirrored from codedthemes.com/demos/admin-templates/mash-able/bootstrap/default/index.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 28 Oct 2020 08:27:44 GMT -->
<head>
    <title></title>
    <!-- HTML5 Shim and Respond.js IE11 support of HTML5 elements and media queries -->
    <!-- WARNING: Respond.js doesn't work if you view the page via file:// -->
    <!--[if lt IE 11]>
    	<script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
    	<script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
    	<![endif]-->
    <!-- Meta -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0, minimal-ui">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="" />
    <meta name="keywords" content="">
    <meta name="author" content="Codedthemes" />
    <!-- Favicon icon -->

    <!-- vendor css -->
    
    

</head>
<body class="">
	<!-- [ Pre-loader ] start -->
	<!-- <div class="loader-bg">
		<div class="loader-track">
			<div class="loader-fill"></div>
		</div>
	</div> -->
    <!-- [ Pre-loader ] End -->
    
    <ngx-spinner></ngx-spinner>

	<!-- [ navigation menu ] start -->
	<app-left-bar *ngIf="router.url !== '/login' && router.url !== '/' && router.url !== '/reset_password'"></app-left-bar>
    <!-- [ navigation menu ] end -->
    
    
    <!-- [ Header ] start -->
	<app-header *ngIf="router.url !== '/login' && router.url !== '/' && router.url !== '/reset_password'"></app-header>
	<!-- [ Header ] end -->
	
	

    <!-- [ Main Content ] start -->
    <router-outlet></router-outlet>
    <!-- [ Main Content ] end -->
    




</body>
</html>
