import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent implements OnInit {

  environment = environment;
  why_us_arr = [];
  submitted = false;
  why_us_section_form_submitted = true
  selected_language = 1;
  why_us_form: FormGroup;
  why_us_section_form: FormGroup;
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.get_why_us_btns(this.selected_language);
    this.add_why_us('');
    this.update_why_us_section();
  }

  // Function to get list of sliders
  get_why_us_btns(language_id) {
    this.spinner.show();
    this.selected_language = language_id;
    this.http.get<any>(environment.base_url+'/get_why_us_btns/'+language_id).subscribe(data => {
      if(data.success) {
        this.why_us_arr = data.data;
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }
  
  // Function to add/update why us section
  onSubmit() {
    this.submitted = true
    if(this.why_us_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/save_why_us_btn', {
      title: this.why_us_form.get('title').value,
      id: this.why_us_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.why_us_form.reset();
        this.add_why_us('');
        this.get_why_us_btns(this.selected_language);
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //  Function to return array of title fields
  add_why_us(why_us_id) {
    if(why_us_id != '') { 
      this.spinner.show();
      this.http.get<any>(environment.base_url+'/get_why_us_btn/'+why_us_id).subscribe(data => {
        if(data.success) {
          if(data.data.why_us_button_translations.length > 0) {
            var title_languages_arr = [];
            data.data.why_us_button_translations.forEach(translation => {
              let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], why_us_section_id:[translation.why_us_section_id, Validators.required], sub_title:[translation.sub_title, Validators.required], description:[translation.description, Validators.required]});
              title_languages_arr.push(title);
            });
            this.why_us_form = this.fb.group({
              id: [why_us_id],
              title: this.fb.array(title_languages_arr),
            })
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            this.toastr.error("Why us section translations not found.", "")    
          }
        } 
        else {
          this.spinner.hide();
          this.toastr.error(data.msg.text, "")  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    else {
      var title_languages_arr = [];
      environment.languages_arr.forEach(translation => {
        let title = this.fb.group({language_code:[translation.code, Validators.required], language_id:[translation.id, Validators.required], why_us_section_id:[1, Validators.required], sub_title:['', Validators.required], description:['', Validators.required]});
        title_languages_arr.push(title);
      });
      this.why_us_form = this.fb.group({
        id: [],
        title: this.fb.array(title_languages_arr),
      })
      this.spinner.hide();
    }
  }

  //  Function to return array of why us section title fields
  update_why_us_section() {
    this.spinner.show();
    this.http.get<any>(environment.base_url+'/get_title/why_us').subscribe(data => {
      if(data.success) {
        if(data.data.why_us_section_translations.length > 0) {
          var title_languages_arr = [];
          data.data.why_us_section_translations.forEach(translation => {
            let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], title:[translation.title, Validators.required]});
            title_languages_arr.push(title);
          });
          this.why_us_section_form = this.fb.group({
            id: [data.data.id],
            title: this.fb.array(title_languages_arr),
          })
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.toastr.error("Why us section translations not found.", "")    
        }
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to update why us section title
  save_why_us_section() {
    this.why_us_section_form_submitted = true
    if(this.why_us_section_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/save_why_us_section', {
      title: this.why_us_section_form.get('title').value,
      id: this.why_us_section_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //  Function to return array of why us button fields
  get titleLanguagesArr() {
    return this.why_us_form.get('title') as FormArray;
  }

  //  Function to return array of why us section title fields
  get whyUsSectionTitleArr() {
    return this.why_us_section_form.get('title') as FormArray;
  }

}
