<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Add New Car</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Add New Car</a></li>
                        </ul>
                    </div>
                </div>  
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <form *ngIf="add_car_form" [formGroup]="add_car_form" (ngSubmit)="onSubmit()">
                        <input type="hidden" formControlname="id">
                        <div class="card-body">
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Car Brand</label>
                                                <select (change)="get_brand_models($event.target.value)" formControlName="car_brand_id" class="js-example-basic-single form-control">
                                                    <option value="">Select Brand</option>
                                                    <option [value]="brand.id" *ngFor="let brand of brands_list_arr">{{brand.eng_title}}</option>
                                                </select>
                                                <label *ngIf="submitted && get_errors.car_brand_id.errors && get_errors.car_brand_id.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Car Model</label>
                                                <select formControlName="car_model_id" class="js-example-basic-single form-control">
                                                    <option value="">Select Model</option>
                                                    <option [value]="model.id" *ngFor="let model of car_models_list_arr">{{model.eng_title}}</option>
                                                </select>
                                                <label *ngIf="submitted && get_errors.car_model_id.errors && get_errors.car_model_id.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Fuel Type</label>
                                                <select formControlName="fuel_type" class="js-example-basic-single form-control">
                                                    <option value="">Select Fuel</option>
                                                    <option value="petrol">Petrol</option>
                                                    <option value="diesel">Diesel</option>
                                                </select>
                                                <label *ngIf="submitted && get_errors.fuel_type.errors && get_errors.fuel_type.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">LPG</label>
                                                <select formControlName="lpg" class="js-example-basic-single form-control">
                                                    <option value="">Select LPG</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                                <label *ngIf="submitted && get_errors.lpg.errors && get_errors.lpg.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div> -->
                                        <div class="col-md-4" *ngIf="environment.car_status.length > 0">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Status</label>
                                                <select formControlName="car_status" class="js-example-basic-single form-control">
                                                    <option value="">Select car status</option>
                                                    <option value="{{status_index}}" *ngFor="let status of environment.car_status;let status_index=index">{{status.eng}}</option>
                                                </select>
                                                <label *ngIf="submitted && get_errors.car_status.errors && get_errors.car_status.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Price</label>
                                                <input type="text" formControlName="price" class="form-control"  aria-describedby="emailHelp" placeholder="Car Price">
                                                <label *ngIf="submitted && get_errors.price.errors && get_errors.price.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Color</label>
                                                <input type="text" formControlName="average" class="form-control"  aria-describedby="emailHelp" placeholder="Car Color">
                                                <label *ngIf="submitted && get_errors.average.errors && get_errors.average.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Year</label>
                                                <input type="date" formControlName="year" class="form-control"  aria-describedby="emailHelp" placeholder="Registration Year">
                                            </div>
                                            <label *ngIf="submitted && get_errors.year.errors && get_errors.year.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">KM</label>
                                                <input type="text" formControlName="km" class="form-control"  aria-describedby="emailHelp" placeholder="KM">
                                            </div>
                                            <label *ngIf="submitted && get_errors.km.errors && get_errors.km.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                        </div>
										  
                                    </div>

                                    <div class="row" *ngIf="car_id != undefined">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Feature Image</label>
                                                <input type="file" class="form-control" name="feature_image" (change)="upload_feature_image($event)"  aria-describedby="emailHelp" placeholder="Upload Image">
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4" *ngIf="car_feature_img_url">
                                            <div class="thumbnail mb-4">
                                                <div class="thumb">
                                                    <img [src]="car_feature_img_url" alt="" class="img-fluid img-thumbnail">
                                                    <!-- <a href="javscript:void();" class="actionbtn"><i class="feather icon-x-circle"></i></a> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <h5>Gallery</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="fallback">
                                                        <input name="file" type="file" multiple (change)="upload_car_images($event)" />
                                                    </div>
                                                </div>
											  <div  class="card-body">
											  <div #rowLayout cdkDropListGroup>
												  <!-- based on row layout's width and item box width, columns per row can be calculated and a items table matrix is initialized-->
												  <div
													*ngFor="let itemsRow of getItemsTable(rowLayout)"
													cdkDropList
													 
													cdkDropListOrientation="horizontal"
													[cdkDropListData]="itemsRow"
													(cdkDropListDropped)="reorderDroppedItem($event)"
												  >
													<!-- drop reorders items and recalculates table matrix-->
													<div class="row" >
													<div class="box col-lg-2 col-sm-4"  *ngFor="let item of itemsRow"  cdkDrag>
													  <!-- <div class="drag-placeholder" *cdkDragPlaceholder></div> -->
														  
														<div class="thumbnail mb-4">
															<div class="thumb example-box">
																<img [src]="image_base_url+'/car_images/'+car_id+'/'+item.img" alt="" class="img-fluid img-thumbnail">
																<a href="javscript:void();" (click)="delete_img(item.id);" class="actionbtn"><i class="feather icon-x-circle"></i></a>
															</div>
														</div>
															 
													</div>
													</div>
												  </div>
												</div>
											 </div>
												
                                                <!-- <div  class="card-body" *ngIf="car_data.car_images.length > 0"> -->
                                                    <!-- <div class="row" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="car_data.car_images"> -->
                                                        <!-- <div  class="col-lg-2 col-sm-4" *ngFor="let car_image of car_data.car_images" cdkDrag> -->
                                                            <!-- <div class="thumbnail mb-4"> -->
                                                                <!-- <div class="thumb"> -->
                                                                    <!-- <img [src]="image_base_url+'/car_images/'+car_id+'/'+car_image.img" alt="" class="img-fluid img-thumbnail"> -->
                                                                    <!-- <a href="javscript:void();" (click)="delete_img(car_image.id);" class="actionbtn"><i class="feather icon-x-circle"></i></a> -->
                                                                <!-- </div> -->
                                                            <!-- </div> -->
                                                        <!-- </div> -->
                                                    <!-- </div> -->
                                                <!-- </div>  -->
												 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages;let language_index=index">
                                            <a class="nav-link" id="pills-{{language}}-tab" data-toggle="pill" href="#pills-{{language}}" role="tab" [class]="language_index == 0 ? 'active':''">{{language}}</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show" *ngFor="let language of environment.languages;let language_index=index" [class]="language_index == 0 ? 'active':''" id="pills-{{language}}" role="tabpanel">
                                            <div *ngIf="language_index == 0" class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Car Name</label>
                                                        <input (keyup) = "convertToSlug($event.target.value)" formControlName="eng_title" type="text" class="form-control"  aria-describedby="emailHelp" placeholder="Car Name">
                                                        <label *ngIf="submitted && get_errors.eng_title.errors && get_errors.eng_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
												
												<div class="col-md-12">
													<div class="form-group">
														<label for="exampleInputSlug">Car Slug </label>
														<input  [ngModel]="add_car_form['slug']" value="{{slug}}" formControlName="slug" type="text" class="form-control" placeholder="Car Slug">
														<label *ngIf="submitted && get_errors.slug.errors && get_errors.slug.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
														<em>eg:- abc-def</em>
													</div>
												</div>
										
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Car Short Description</label>
                                                        <textarea class="form-control" formControlName="short_eng_description" placeholder="Short Description"></textarea>
                                                        <label *ngIf="submitted && get_errors.short_eng_description.errors && get_errors.short_eng_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Car Long Description</label>
                                                        <ckeditor [editor]="Editor" formControlName="eng_description"></ckeditor>
                                                        <label *ngIf="submitted && get_errors.eng_description.errors && get_errors.eng_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12">
                                                    <h5 class="mb-3">Car Features</h5>
                                                    <hr>
                                                    <div class="accordion" id="accordionExample">
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingOne">
                                                                <h5 class="mb-0"><a href="#!" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed">Car Details</a></h5>
                                                            </div>
                                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="eng_car_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of englishCarDetails.controls; let pointIndex=index" [formGroupName]="pointIndex">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Label">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Value">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-2 nopadding" *ngIf="englishCarDetails.controls.length != pointIndex+1">
                                                                                                    <div class="form-group">
                                                                                                        <div class="input-group">
                                                                                                            <div class="input-group-btn">
                                                                                                                <button type="button" class="btn btn-icon btn-danger" (click)="remove_field(pointIndex, 'eng_car_details')"><i class="feather icon-trash"></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-2 nopadding" *ngIf="englishCarDetails.controls.length == pointIndex+1">
                                                                                                    <div class="form-group">
                                                                                                        <div class="input-group">
                                                                                                            <div class="input-group-btn">
                                                                                                                <button type="button" class="btn btn-icon btn-success" (click)="add_field('eng_car_details')"><i class="feather icon-plus"></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingTwo">
                                                                <h5 class="mb-0"><a href="#!" class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Technical Details</a></h5>
                                                            </div>
                                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="eng_technical_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of engTechDetails.controls; let tech_index=index" [formGroupName]="tech_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Label">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Value">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-2 nopadding" *ngIf="engTechDetails.controls.length != tech_index+1">
                                                                                                    <div class="form-group">
                                                                                                        <div class="input-group">
                                                                                                            <div class="input-group-btn">
                                                                                                                <button type="button" class="btn btn-icon btn-danger" (click)="remove_field(tech_index, 'eng_technical_details')"><i class="feather icon-trash"></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-2 nopadding" *ngIf="engTechDetails.controls.length == tech_index+1">
                                                                                                    <div class="form-group">
                                                                                                        <div class="input-group">
                                                                                                            <div class="input-group-btn">
                                                                                                                <button type="button" class="btn btn-icon btn-success" (click)="add_field('eng_technical_details')"><i class="feather icon-plus"></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingThree">
                                                                <h5 class="mb-0"><a href="#!" class="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Individual Configuration</a></h5>
                                                            </div>
                                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="eng_individual_config">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of engIndividualConfig.controls; let individual_index=index" [formGroupName]="individual_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Label">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Value">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-2 nopadding" *ngIf="engIndividualConfig.controls.length != individual_index+1">
                                                                                                    <div class="form-group">
                                                                                                        <div class="input-group">
                                                                                                            <div class="input-group-btn">
                                                                                                                <button type="button" class="btn btn-icon btn-danger" (click)="remove_field(individual_index, 'eng_individual_config')"><i class="feather icon-trash"></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-2 nopadding" *ngIf="engIndividualConfig.controls.length == individual_index+1">
                                                                                                    <div class="form-group">
                                                                                                        <div class="input-group">
                                                                                                            <div class="input-group-btn">
                                                                                                                <button type="button" class="btn btn-icon btn-success" (click)="add_field('eng_individual_config')"><i class="feather icon-plus"></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div *ngIf="language_index == 1" class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Nombre del coche</label>
                                                        <input type="text" formControlName="spa_title" class="form-control"  aria-describedby="emailHelp" placeholder="Nombre del coche">
                                                        <label *ngIf="submitted && get_errors.spa_title.errors && get_errors.spa_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Descripción breve del coche</label>
                                                        <textarea class="form-control" formControlName="short_spa_description" placeholder="Breve descripción"></textarea>
                                                        <label *ngIf="submitted && get_errors.short_spa_description.errors && get_errors.short_spa_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Descripción del coche</label>
                                                        <ckeditor [editor]="Editor" formControlName="spa_description"></ckeditor>
                                                        <label *ngIf="submitted && get_errors.spa_description.errors && get_errors.spa_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12">
                                                    <h5 class="mb-3">Características del coche</h5>
                                                    <hr>
                                                    <div class="accordion" id="accordionExample">
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingOne">
                                                                <h5 class="mb-0"><a href="#!" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed">Detalles del coche</a></h5>
                                                            </div>
                                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="spa_car_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of spaCarDetails.controls; let spa_car_index=index" [formGroupName]="spa_car_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Etiqueta">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Valor">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingTwo">
                                                                <h5 class="mb-0"><a href="#!" class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Detalles técnicos</a></h5>
                                                            </div>
                                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="spa_technical_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of spaTechDetails.controls; let spa_tech_index=index" [formGroupName]="spa_tech_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Etiqueta">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Valor">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingThree">
                                                                <h5 class="mb-0"><a href="#!" class="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Configuración individual</a></h5>
                                                            </div>
                                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="spa_individual_config">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of spaIndividualConfig.controls; let spa_individual_index=index" [formGroupName]="spa_individual_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Etiqueta">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Valor">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                            <div *ngIf="language_index == 2" class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Autoname</label>
                                                        <input type="text" class="form-control" formControlName="ger_title"  aria-describedby="emailHelp" placeholder="Autoname">
                                                        <label *ngIf="submitted && get_errors.ger_title.errors && get_errors.ger_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Auto Kurzbeschreibung</label>
                                                        <textarea class="form-control" formControlName="short_ger_description" placeholder="kurze Beschreibung"></textarea>
                                                        <label *ngIf="submitted && get_errors.short_ger_description.errors && get_errors.short_ger_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Fahrzeugbeschreibung</label>
                                                        <ckeditor [editor]="Editor" formControlName="ger_description"></ckeditor>
                                                        <label *ngIf="submitted && get_errors.ger_description.errors && get_errors.ger_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12">
                                                    <h5 class="mb-3">Auto Eigenschaften</h5>
                                                    <hr>
                                                    <div class="accordion" id="accordionExample">
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingOne">
                                                                <h5 class="mb-0"><a href="#!" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed">Autodetails</a></h5>
                                                            </div>
                                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="ger_car_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of gerCarDetails.controls; let ger_car_index=index" [formGroupName]="ger_car_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Etikette">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Wert">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingTwo">
                                                                <h5 class="mb-0"><a href="#!" class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Technische Details</a></h5>
                                                            </div>
                                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="ger_technical_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of gerTechDetails.controls; let ger_tech_index=index" [formGroupName]="ger_tech_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Etikette">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Wert">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingThree">
                                                                <h5 class="mb-0"><a href="#!" class="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Individuelle Konfiguration</a></h5>
                                                            </div>
                                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="ger_individual_config">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of gerIndividualConfig.controls; let ger_individual_index=index" [formGroupName]="ger_individual_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="Etikette">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Wert">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="language_index == 3" class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Имя автомобиля</label>
                                                        <input type="text" class="form-control" formControlName="rus_title"  aria-describedby="emailHelp" placeholder="Имя автомобиля">
                                                        <label *ngIf="submitted && get_errors.rus_title.errors && get_errors.rus_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Краткое описание автомобиля</label>
                                                        <textarea class="form-control" formControlName="short_rus_description" placeholder="Краткое описание"></textarea>
                                                        <label *ngIf="submitted && get_errors.short_rus_description.errors && get_errors.short_rus_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Описание автомобиля</label>
                                                        <ckeditor [editor]="Editor" formControlName="rus_description"></ckeditor>
                                                        <label *ngIf="submitted && get_errors.rus_description.errors && get_errors.rus_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12">
                                                    <h5 class="mb-3">Особенности автомобиля</h5>
                                                    <hr>
                                                    <div class="accordion" id="accordionExample">
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingOne">
                                                                <h5 class="mb-0"><a href="#!" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed">Детали автомобиля</a></h5>
                                                            </div>
                                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="rus_car_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of rusCarDetails.controls; let rus_car_index=index" [formGroupName]="rus_car_index" >
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="этикетка">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Значение">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card mb-0">
                                                            <div class="card-header" id="headingTwo">
                                                                <h5 class="mb-0"><a href="#!" class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Технические детали</a></h5>
                                                            </div>
                                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="rus_technical_details">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of rusTechDetails.controls; let rus_tech_index=index" [formGroupName]="rus_tech_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="этикетка">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Значение">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingThree">
                                                                <h5 class="mb-0"><a href="#!" class="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Индивидуальная конфигурация</a></h5>
                                                            </div>
                                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample" style="">
                                                                <div class="card-body">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 nopadding" formArrayName="rus_individual_config">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 nopadding" *ngFor="let item of rusIndividualConfig.controls; let rus_individual_index=index" [formGroupName]="rus_individual_index">
                                                                                            <div class="row">  
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="label" value="" placeholder="этикетка">
                                                                                                        <label *ngIf="submitted && item.controls.label.errors && item.controls.label.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-5 nopadding">
                                                                                                    <div class="form-group">
                                                                                                        <input type="text" class="form-control"  formControlName="value" value="" placeholder="Значение">
                                                                                                        <label *ngIf="submitted && item.controls.value.errors && item.controls.value.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
                <!-- Input group -->
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>
