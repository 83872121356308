import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr'
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list.component.html',
  styleUrls: ['./cars-list.component.css']
})
export class CarsListComponent implements OnInit {

  environment = environment;
  cars_arr = [];
  image_base_url = environment.image_base_url
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.get_cars();    
  }

  get_cars() {
    this.http.get<any>(environment.base_url+'/get_cars').subscribe(data => {
      if(data.success) {
        this.cars_arr = data.data;
        this.spinner.hide();
        //console.log(data)
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to delete car
  delete_car(car_id) 
  {
    if(confirm('Are you sure to delete this car?')) {
      if(car_id != null && car_id != undefined && car_id != '') {
        this.http.post<any>(environment.base_url+'/delete_car', {
          car_id: car_id,
        }).subscribe(data => {
          if(data.success) {
            this.get_cars();
            this.spinner.hide();
            this.toastr.success(data.msg.text, "");            
            //console.log(data)
          } 
          else {
            for(var i in data.msg) {
              this.spinner.hide();
              this.toastr.error(data.msg[i], "");
            }  
          }
        }),
        (error) => this.toastr.error(error.message, "")  
      }
      else {
        this.toastr.error("Invalid parameter.", "");  
      }
    }
  }

   drop(event: CdkDragDrop<string[]>) {
     moveItemInArray(this.cars_arr, event.previousIndex, event.currentIndex);
     this.http.post<any>(environment.base_url+'/order_cars',this.cars_arr).subscribe(data => {
      if(data.success) {
        this.cars_arr = data.data;
        this.spinner.hide();
        //console.log(data)
      } 
      else {
        this.spinner.hide();
        for(var i in data.msg) {
           this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => { this.toastr.error(error.message, "");  this.spinner.hide();}
  }

}
