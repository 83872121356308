<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Add New Blog</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Add New Blog</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row" *ngIf="blog_categories.length > 0;else no_category_found">
            <div class="col-sm-12" *ngIf="environment.languages_arr.length > 0">
                <form *ngIf="blog_form" [formGroup]="blog_form" (ngSubmit)="save_blog()">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Blog Category</label>
                                        <select formControlName="blog_category_id" class="js-example-basic-single form-control">
                                            <option value="">Select Blog category</option>
                                            <option value="{{blog_category.id}}" *ngFor="let blog_category of blog_categories">{{blog_category.category_translation.title}}</option>
                                        </select>
                                        <label *ngIf="submitted && get_errors.blog_category_id.errors && get_errors.blog_category_id.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Blog Type</label>
                                        <select formControlName="blog_type" (change)="blog_type($event.target.value);" class="js-example-basic-single form-control" name="btype">
                                            <option value="image">Image</option>
                                            <option value="video">Video</option>
                                        </select>
                                        <label *ngIf="submitted && get_errors.blog_type.errors && get_errors.blog_type.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="blog_id != null">
                                <div class="col-md-4 image">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Feature Image</label>
                                        <input (change)="upload_image($event, 'image')" type="file" class="form-control"  aria-describedby="emailHelp" placeholder="Upload Image">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-sm-4 image">
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <img [src]="img_url" alt="" class="img-fluid img-thumbnail">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 imag video">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Thumbnail Image</label>
                                        <input (change)="upload_image($event, 'thumbnail')" type="file" class="form-control"  aria-describedby="emailHelp" placeholder="Upload Image">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-sm-4 imag video">
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <img [src]="thumbnail_url" alt="" class="img-fluid img-thumbnail">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 vid video">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Video URL</label>
                                        <input formControlName="video_url" type="url" class="form-control"  aria-describedby="emailHelp" placeholder="Video URL">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-sm-4 vid video">
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <iframe width="150" height="100" [src]="youtube_video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Blog Name</label>
                                                        <input formControlName="title" type="text" class="form-control" aria-describedby="emailHelp" placeholder="Blog Name">
                                                        <label *ngIf="submitted && item.controls.title.errors && item.controls.title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Blog Description</label>
                                                        <textarea formControlName="description" class="form-control" placeholder="Description"></textarea>
                                                        <label *ngIf="submitted && item.controls.description.errors && item.controls.description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <button type="submit" class="btn  btn-primary">Save</button>
                                            </div>
                                            <!-- <div class="col-md-4">
                                                <button type="submit" class="btn  btn-primary">Translate To All</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- Input group -->
            </div>
        </div>
        <ng-template #no_category_found>
            <div>
                Atleast one blog category required to add blog.
            </div>
        </ng-template>
        <!-- [ Main Content ] end -->
    </div>
</div>
