import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-parallax',
  templateUrl: './parallax.component.html',
  styleUrls: ['./parallax.component.css']
})
export class ParallaxComponent implements OnInit {

  environment = environment;
  add_parallax_form: FormGroup;
  submitted = false;
  fileData: File = null;
  parallax_img_url:any = ''; 
  selected_language = '';
  parallax_id = '';
  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.http.get<any>(environment.base_url+'/get_parallax_details').subscribe(data => {
      if(data.success) {
        this.parallax_img_url = environment.image_base_url+'/parallax_bg_img/'+data.data.id+'/'+data.data.img;
        this.parallax_id = data.data.id;
        if(data.data.parallax_section_translations.length > 0) {
          var title_languages_arr = [];
          data.data.parallax_section_translations.forEach(translation => {
            let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], title:[translation.title, Validators.required], btn_title:[translation.btn_title, Validators.required]});
            title_languages_arr.push(title);
          });
          this.add_parallax_form = this.fb.group({
            id: [data.data.id],
            title: this.fb.array(title_languages_arr),
          })
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.toastr.error("Parallax translations not found.", "")    
        }
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to return update main menu form errors
  get get_errors() { 
    return this.add_parallax_form.controls 
  }
  
  // Function to update main menu
  onSubmit() {
    this.submitted = true
    if(this.add_parallax_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/save_parallax', {
      title: this.add_parallax_form.get('title').value,
      id: this.add_parallax_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to upload slider image
  upload_parallax_image(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Please select image file.", "");
      return
    }
    if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png') {
      if(this.fileData.size > 5000000) {
        this.toastr.error("Image file size should not be greater than 5MB.", "");  
        return;
      }
      this.spinner.show();
      const formData = new FormData();
      formData.append('file', this.fileData, this.parallax_id+'.'+mimeType.substr(6));
      this.http.post<any>(environment.base_url+'/upload_parallax_image', formData).subscribe(data => {
        if(data.success) {
          //console.log(data)
          var reader = new FileReader();      
          reader.readAsDataURL(this.fileData); 
          reader.onload = (_event) => { 
            this.parallax_img_url = reader.result; 
          }
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")  
    }
    else {
      this.toastr.error("Image should be PNG|JPEG|JPG.", "");
      return
    }
  }

  

  //  Function to return array of title fields
  get titleLanguagesArr() {
    return this.add_parallax_form.get('title') as FormArray;
  }

}
