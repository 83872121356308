<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Add Blog Category & Type</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Add Blog Category & Type</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row" *ngIf="environment.languages_arr.length > 0">
            <div class="col-sm-12">
                <form *ngIf="blog_category_form" [formGroup]="blog_category_form" (ngSubmit)="save_category()">
                    <div class="card">
                        <div class="card-header">
                            <h5>Add Blog Category</h5>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Category Name</label>
                                                        <input type="text" formControlName="title" class="form-control" aria-describedby="emailHelp" placeholder="Category Name">
                                                        <label *ngIf="submitted && item.controls.title.errors && item.controls.title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <button type="submit" class="btn  btn-primary">Save</button>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                    <button type="submit" class="btn  btn-primary">Translate To All</button>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card user-profile-list">
                        <div class="card-header">
                            <h5>Blog Category List</h5>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                        <select class="form-control" style="width:200px" (change)="get_categories($event.target.value)">
                                            <option [value]="language.id" *ngFor="let language of environment.languages_arr">
                                                {{language.language}}
                                            </option>
                                        </select>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent1">
                                        <div class="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Category Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf="categories_arr.length > 0;else categories_not_dound">
                                                        <tr *ngFor="let category of categories_arr">
                                                            <td>{{category.category_translation.title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button (click)="add_category(category.id);" type="button" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></button>
                                                                    <!-- <button (click)="delete_category(category.id);" type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <ng-template #categories_not_dound>
                                                        <div style="text-align:centerl;font-size:18px;">
                                                            No blog found.
                                                        </div>
                                                    </ng-template>
                                                    <tfoot>
                                                        <tr>
                                                            <th>Category Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>