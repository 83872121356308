import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { LoginComponent } from './auth/login/login.component';
import { LeftBarComponent } from './shared/left-bar/left-bar.component';
import { HeaderComponent } from './shared/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { CarsListComponent } from './cars/cars-list/cars-list.component';
import { AddCarComponent } from './cars/add-car/add-car.component';
import { AddBrandComponent } from './cars/add-brand/add-brand.component';
import { MenusListComponent } from './menus/menus/menus-list/menus-list.component';
import { SubMenusListComponent } from './menus/sub_menus/sub-menus-list/sub-menus-list.component';
import { SliderComponent } from './home/slider/slider.component';
import { WhyUsComponent } from './home/why-us/why-us.component';
import { TestimonialsComponent } from './testimonials/testimonials/testimonials.component';
import { AddTestimonialComponent } from './testimonials/add-testimonial/add-testimonial.component';
import { ParallaxComponent } from './home/parallax/parallax.component';
import { LogosComponent } from './logos/logos.component';
import { BannerComponent } from './investor/banner/banner.component';
import { BlogsComponent } from './blogs/blogs/blogs.component';
import { AddBlogComponent } from './blogs/add-blog/add-blog.component';
import { BlogCategoriesComponent } from './blogs/blog-categories/blog-categories.component';
import { SuccessStoryComponent } from './success-story/success-story.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {SamDragAndDropGridModule} from '@sam-1994/ngx-drag-and-drop-grid';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LeftBarComponent,
    HeaderComponent,
    CarsListComponent,
    AddCarComponent,
    AddBrandComponent,
    MenusListComponent,
    SubMenusListComponent,
    SliderComponent,
    WhyUsComponent,
    TestimonialsComponent,
    AddTestimonialComponent,
    ParallaxComponent,
    LogosComponent,
    BannerComponent,
    BlogsComponent,
    AddBlogComponent,
    BlogCategoriesComponent,
    SuccessStoryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SamDragAndDropGridModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    CKEditorModule,
    DragDropModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuardService, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true 
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
