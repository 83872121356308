<div class="auth-wrapper align-items-stretch aut-bg-img">
    <div class="flex-grow-1">
        <div class="h-100 d-md-flex align-items-center auth-side-img">
            <div class="col-sm-10 auth-content w-auto">
                <img src="assets/images/logo-white.svg" width="280" alt="" class="img-fluid">
                <h1 class="text-white my-4">Welcome Back!</h1>
                <h4 class="text-white font-weight-normal">Signin to your account and get explore the Carisma Classic Dashboard.</h4>
            </div>
        </div>
        <div class="auth-side-form">
            <div class=" auth-content">
                <img src="assets/images/auth/auth-logo-dark.png" alt="" class="img-fluid mb-4 d-block d-xl-none d-lg-none">
                <h3 class="mb-4 f-w-400">Login Here</h3>
                <form [formGroup]="login_form">
                    <div class="form-group mb-3">
                        <input type="text" class="form-control" formControlName="email" id="Email" placeholder="Email">
                        <label *ngIf="submitted && get_errors.email.errors && get_errors.email.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                        <label *ngIf="submitted && get_errors.email.errors && get_errors.email.errors.email" class="error small form-text invalid-feedback" for="validation-email">Email must be a valid email address</label>
                    </div>
                    <div class="form-group mb-4">
                        <input type="password" class="form-control" formControlName="password" id="Password" placeholder="Password">
                        <label *ngIf="submitted && get_errors.password.errors && get_errors.password.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                    </div>
                    <!-- <div class="custom-control custom-checkbox text-left mb-4 mt-2">
                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                        <label class="custom-control-label" for="customCheck1">Save credentials.</label>
                    </div> -->
                    <button class="btn btn-block btn-primary mb-4" (click)="login()">Login</button>
                    <!-- <div class="text-center">
                        <div class="saprator my-4"><span>OR</span></div>
                        <p class="mb-2 mt-4 text-muted">Forgot password? <a href="forgot-password.html" class="f-w-400">Reset</a></p>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
</div>