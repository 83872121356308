import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import {ActivatedRoute, Router} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.css']
})
export class AddBlogComponent implements OnInit {
  environment = environment;
  submitted = false;
  selected_language = 1;
  blog_form: FormGroup;
  blog_id = '';
  blog_categories = [];
  youtube_video:any = '';
  fileData: File = null;
  thumbnail_url:any = '';
  img_url:any = '';
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private dom:DomSanitizer,
    private router: Router
  ) 
  { 
    this.youtube_video = this.dom.bypassSecurityTrustResourceUrl("");
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.blog_id = params['id'];
    });
    this.add_blog(this.blog_id);
  }

  blog_type(type) {
    setTimeout(function(){ 
      if(type == 'image') {
        var hide_car_images:any = document.getElementsByClassName('video');
        for(var i = 0; i < hide_car_images.length; i++ ) {
          hide_car_images[i].style.display = 'none';
        }
  
        var hide_car_images:any = document.getElementsByClassName('image');
        for ( var i = 0; i < hide_car_images.length; i++ ) {
          hide_car_images[i].style.display = 'block';
        }
      } 
      else {
        var hide_car_images:any = document.getElementsByClassName('image');
        for ( var i = 0; i < hide_car_images.length; i++ ) {
          hide_car_images[i].style.display = 'none';
        }
        var hide_video:any = document.getElementsByClassName('video');
        for (var i = 0; i < hide_video.length; i++ ) {
          hide_video[i].style.display = 'block';
        }
      } 
    }, 500);
    
  }

  //Function to add blog category
  add_blog(blog_id) {
    this.http.get<any>(environment.base_url+'/get_categories/0').subscribe(data => {
      if(data.success) {
        this.blog_categories = data.data;
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")

    if(blog_id != '' && blog_id != undefined) { 
      this.http.get<any>(environment.base_url+'/get_blog_content/'+blog_id).subscribe(data => {
        if(data.success) {
          console.log(data);
          this.youtube_video = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+data.data.youtube_video_id);
          this.blog_type(data.data.type);
          this.thumbnail_url = environment.image_base_url+'/blog_images/'+data.data.id+'/'+data.data.video_thumbnail;
          this.img_url = environment.image_base_url+'/blog_images/'+data.data.id+'/'+data.data.img;
          if(data.data.blog_translations.length > 0) {
            var title_languages_arr = [];
            data.data.blog_translations.forEach(translation => {
              let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], title:[translation.title, Validators.required], description:[translation.description, Validators.required]});
              title_languages_arr.push(title);
            });
            this.blog_form = this.fb.group({
              id: [blog_id],
              blog_category_id: [data.data.blog_category_id, Validators.required],
              blog_type: [data.data.type, Validators.required],
              video_url: [data.data.youtube_video_url],
              title: this.fb.array(title_languages_arr)
            })
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            this.toastr.error("Blog translations not found.", "")    
          }
        } 
        else {
          this.spinner.hide();
          this.toastr.error(data.msg.text, "")  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    else {
      var title_languages_arr = [];
      environment.languages_arr.forEach(translation => {
        let title = this.fb.group({language_code:[translation.code, Validators.required], language_id:[translation.id, Validators.required], title:['', Validators.required], description:['', Validators.required]});
        title_languages_arr.push(title);
      });
      this.blog_form = this.fb.group({
        id: [],
        blog_category_id: ['', Validators.required],
        blog_type: ['image', Validators.required],
        video_url: [''],
        title: this.fb.array(title_languages_arr),
      })
      this.spinner.hide();
    }
  }

  //Function to save blog
  save_blog() {
    this.submitted = true
    if(this.blog_form.invalid) {
      return 
    }
    let youtube_video_id = '';
    if(this.blog_form.get('video_url').value != null && this.blog_form.get('video_url').value != undefined && this.blog_form.get('video_url').value != '') {
      youtube_video_id = this.youtube_parser(this.blog_form.get('video_url').value);  
      this.youtube_video = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+youtube_video_id);
    }
    this.http.post<any>(environment.base_url+'/save_blog', {
      title: this.blog_form.get('title').value,
      blog_category_id: this.blog_form.get('blog_category_id').value,
      blog_type: this.blog_form.get('blog_type').value,
      video_url: this.blog_form.get('video_url').value,
      id: this.blog_form.get('id').value,
      youtube_video_id: youtube_video_id,
    }).subscribe(data => {
      if(data.success) {
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
        if(this.blog_id == null) {
          this.router.navigateByUrl('/update_blog/'+data.data);
        }
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to extract youtube video id
  youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  //Function to upload slider image
  upload_image(fileInput: any, type) {
    this.fileData = <File>fileInput.target.files[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Please select image file.", "");
      return
    }
    if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png') {
      if(this.fileData.size > 5000000) {
        this.toastr.error("Image file size should not be greater than 5MB.", "");  
        return;
      }
      const formData = new FormData();
      if(type == 'image') {
        formData.append('file', this.fileData, this.blog_id+'_image.'+mimeType.substr(6));
      }
      else {
        formData.append('file', this.fileData, this.blog_id+'_thumbnail.'+mimeType.substr(6));
      } 
      this.http.post<any>(environment.base_url+'/upload_blog_image', formData).subscribe(data => {
        if(data.success) {
          //console.log(data)
          var reader = new FileReader();      
          reader.readAsDataURL(this.fileData); 
          reader.onload = (_event) => { 
            if(type == 'image') {
              //formData.append('file', this.fileData, this.blog_id+'_image.'+mimeType.substr(6));
              this.img_url = reader.result; 
            }
            else {
              this.thumbnail_url = reader.result;
              //formData.append('file', this.fileData, this.blog_id+'_thumbnail.'+mimeType.substr(6));
            } 
          }
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")  
    }
    else {
      this.toastr.error("Image should be PNG|JPEG|JPG.", "");
      return
    }
  }

  // Function to return 
  get get_errors() { 
    return this.blog_form.controls 
  }

  //  Function to return array of blogs form
  get titleLanguagesArr() {
    return this.blog_form.get('title') as FormArray;
  }

}
