import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthGuardService } from '../services/auth-guard/auth-guard.service';
import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    let currentUser = JSON.parse(localStorage.getItem('userDetails'));
    let get_language_code = localStorage.getItem('selected_language');
    let language_code = 'eng';
    if(get_language_code != null) {
      language_code = get_language_code;
    }
    let token = '';
    if(currentUser == null) {
      token = '';
    }
    else {
      token = currentUser['data']['token'];
    }
    request = request.clone({
        setHeaders: {
        Authorization: 'Bearer '+token,
        LanguageCode: language_code
        },
    });
    return next.handle(request);
  }
}