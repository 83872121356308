<nav class="pcoded-navbar menupos-fixed ">
    <div class="navbar-wrapper  ">
        <div class="navbar-content scroll-div ps ps--active-y">

            <div class="">
                <div class="main-menu-header">
                    <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="User-Profile-Image">
                    <div class="user-details">
                        <span class="mb-0 font-weight-bold">{{user_details.first_name}} {{user_details.last_name}}</span>
                        <!-- <div id="more-details"><small>UX Designer<i class="fa fa-chevron-down m-l-5"></i></small></div> -->
                    </div>
                </div>
                <!-- <div class="collapse" id="nav-user-link">
                    <ul class="list-inline">
                        <li class="list-inline-item"><a href="javascript:;" data-toggle="tooltip" title="View Profile"><i class="feather icon-user"></i></a></li>
                        <li class="list-inline-item"><a href="javascript:;"><i class="feather icon-mail" data-toggle="tooltip" title="Messages"></i><small class="badge badge-pill badge-primary">5</small></a></li>
                        <li class="list-inline-item"><a href="javascript:;" data-toggle="tooltip" title="Logout" class="text-danger"><i class="feather icon-power"></i></a></li>
                    </ul>
                </div> -->
            </div>

            <ul class="nav pcoded-inner-navbar">
                <li class="nav-item pcoded-menu-caption">
                    <label>Navigation</label>
                </li>

                <li class="nav-item pcoded-hasmenu" [ngClass]="{'active pcoded-trigger': router.url == '/menus' || router.url == '/sub_menus'}">
                    <a [routerLink]="['/menus']" class="nav-link "><span class="pcoded-micon"><i class="feather icon-align-left"></i></span><span class="pcoded-mtext">Menus</span></a>
                    <ul class="pcoded-submenu">
                        <li><a [routerLink]="['/menus']">Main Menu</a></li>
                        <li><a [routerLink]="['/sub_menus']">Sub Menu</a></li>
                    </ul>
                </li>

                <li class="nav-item" [ngClass]="{'active pcoded-trigger': router.url == '/logos'}"><a [routerLink]="['/logos']" class="nav-link "><span class="pcoded-micon"><i class="feather icon-aperture"></i></span><span class="pcoded-mtext">Logos</span></a></li>

                <li class="nav-item pcoded-hasmenu" [ngClass]="{'active pcoded-trigger': router.url == '/slider' || router.url == '/why_us' || router.url == '/parallax'}">
                    <a [routerLink]="['/slider']" class="nav-link "><span class="pcoded-micon"><i class="feather icon-home"></i></span><span class="pcoded-mtext">Home</span></a>
                    <ul class="pcoded-submenu">
                        <li><a [routerLink]="['/slider']">Slider</a></li>
                        <li><a [routerLink]="['/why_us']">Why us</a></li>
                        <li><a [routerLink]="['/parallax']">Parallax</a></li>
                    </ul>
                </li>
                <li class="nav-item" [ngClass]="{'active pcoded-trigger': router.url == '/success_story'}">
                    <a [routerLink]="['success_story']" class="nav-link "><span class="pcoded-micon"><i class="feather icon-file-text"></i></span><span class="pcoded-mtext">Success Story</span></a>
                </li>
                <li class="nav-itempcoded-trigger" [ngClass]="{'active pcoded-trigger': router.url == '/investor/banner'}">
                    <a [routerLink]="['/investor/banner']" class="nav-link "><span class="pcoded-micon"><i class="feather icon-file-text"></i></span><span class="pcoded-mtext">Investor</span></a>
                </li>
                <li class="nav-item pcoded-hasmenu" [ngClass]="{'active pcoded-trigger': router.url == '/cars' || router.url == '/add_brand' || router.url == '/add_car'}">
                    <a [routerLink]="['/cars']" class="nav-link "><span class="pcoded-micon"><i class="feather icon-home"></i></span><span class="pcoded-mtext">Car Detail</span></a>
                    <ul class="pcoded-submenu">
                        <li><a [routerLink]="['/cars']">View Cars</a></li>
                        <li><a [routerLink]="['/add_brand']">Add Brand</a></li>
                        <li ><a [routerLink]="['/add_car']">Add New Car</a></li>
                    </ul>
                </li>
                <li class="nav-item pcoded-hasmenu" [ngClass]="{'active pcoded-trigger': router.url == '/blogs' || router.url == '/blog_categories' || router.url == '/add_blog'}">
                    <a [routerLink]="['/blogs']" class="nav-link"><span class="pcoded-micon"><i class="feather icon-home"></i></span><span class="pcoded-mtext">Blog Detail</span></a>
                    <ul class="pcoded-submenu">
                        <li><a [routerLink]="['/blogs']">View Blogs</a></li>
                        <li><a [routerLink]="['/blog_categories']">Add Category</a></li>
                        <li><a [routerLink]="['/add_blog']">Add Blog</a></li>
                    </ul>
                </li>
                <li class="nav-item pcoded-hasmenu" [ngClass]="{'active pcoded-trigger': router.url == '/testimonials' || router.url == '/add_testimonial'}">
                    <a [routerLink]="['/testimonials']" class="nav-link "><span class="pcoded-micon"><i class="feather icon-home"></i></span><span class="pcoded-mtext">Testimonial Detail</span></a>
                    <ul class="pcoded-submenu">
                        <li><a [routerLink]="['/testimonials']">View Testimonial</a></li>
                        <li><a [routerLink]="['/add_testimonial']">Add Testimonial</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>