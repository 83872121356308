import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-success-story',
  templateUrl: './success-story.component.html',
  styleUrls: ['./success-story.component.css']
})
export class SuccessStoryComponent implements OnInit {

  environment = environment;
  submitted = false;
  selected_language = 1;
  story_form: FormGroup;
  category_id = '';
  categories_arr = [];
  paralax_img_url:any = '';
  author_img_url:any = '';
  fileData: File = null;
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.add_success_story();
  }

  //Function to add success story
  add_success_story() {
    this.http.get<any>(environment.base_url+'/get_story').subscribe(data => {
      if(data.success) {
        if(data.data.story_translations.length > 0) {
          var title_languages_arr = [];
          this.paralax_img_url = environment.image_base_url+'/success_story_images/'+data.data.bg_img;
          this.author_img_url = environment.image_base_url+'/success_story_images/'+data.data.author_img;
          data.data.story_translations.forEach(translation => {
            let title = this.fb.group({
              language_code:[translation.language_code, Validators.required], 
              language_id:[translation.language_id, Validators.required],
              title:[translation.title, Validators.required],
              description:[translation.description, Validators.required],
              parallax_title:[translation.parallax_title, Validators.required],
              parallax_description:[translation.parallax_description, Validators.required],
              author_name:[translation.author_name, Validators.required],
              btn_title:[translation.btn_title, Validators.required],
              parallax_inverted_title:[translation.parallax_inverted_title, Validators.required],
            });
            title_languages_arr.push(title);
          });
          this.story_form = this.fb.group({
            id: [data.data.id],
            title: this.fb.array(title_languages_arr),
          })
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.toastr.error("Testimonial translations not found.", "")    
        }
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to save blog category
  save_story() {
    this.submitted = true
    if(this.story_form.invalid) {
      return 
    }
    this.http.post<any>(environment.base_url+'/save_story', {
      title: this.story_form.get('title').value,
      id: this.story_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
        this.add_success_story();
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //  Function to return array of story form fields
  get titleLanguagesArr() {
    return this.story_form.get('title') as FormArray;
  }

  //Function to upload slider image
  upload_image(fileInput: any, type) {
    this.fileData = <File>fileInput.target.files[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Please select image file.", "");
      return
    }
    if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png') {
      if(this.fileData.size > 5000000) {
        this.toastr.error("Image file size should not be greater than 5MB.", "");  
        return;
      }
      const formData = new FormData();
      if(type == 'author_image') {
        formData.append('file', this.fileData, 'author_image.'+mimeType.substr(6));
      }
      else {
        formData.append('file', this.fileData, 'bg_img.'+mimeType.substr(6));
      } 
      this.http.post<any>(environment.base_url+'/upload_success_story_image', formData).subscribe(data => {
        if(data.success) {
          //console.log(data)
          var reader = new FileReader();      
          reader.readAsDataURL(this.fileData); 
          reader.onload = (_event) => { 
            if(type == 'bg_img') {
              this.paralax_img_url = reader.result; 
            }
            else {
              this.author_img_url = reader.result;
            } 
          }
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")  
    }
    else {
      this.toastr.error("Image should be PNG|JPEG|JPG.", "");
      return
    }
  }
}
