import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import {ActivatedRoute, Router} from '@angular/router';
@Component({
  selector: 'app-add-testimonial',
  templateUrl: './add-testimonial.component.html',
  styleUrls: ['./add-testimonial.component.css']
})
export class AddTestimonialComponent implements OnInit {

  environment = environment;
  testimonial_form_submitted = false;
  section_title_form_submitted = false
  selected_language = 1;
  testimonial_form: FormGroup;
  section_title_form: FormGroup;
  testimonial_id = '';
  fileData: File = null;
  author_img_url:any = ''; 
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.testimonial_id = params['id'];
    });
    this.add_testimonial(this.testimonial_id);
    this.update_section_title();
  }
  
  // Function to add/update testimonial
  onSubmit() {
    this.testimonial_form_submitted = true
    if(this.testimonial_form.invalid) {
      return 
    }
    this.http.post<any>(environment.base_url+'/save_testimonial', {
      title: this.testimonial_form.get('title').value,
      id: this.testimonial_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        if(this.testimonial_id == '' || this.testimonial_id == undefined) {
          this.testimonial_id = data.data;
          this.router.navigateByUrl('/update_testimonial/'+this.testimonial_id);
          this.add_testimonial(this.testimonial_id);
        } 
        else {
          this.router.navigateByUrl('/testimonials');
          this.spinner.hide();  
        }
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //  Function to return array of title fields
  add_testimonial(testimonial_id) {
    if(testimonial_id != '' && testimonial_id != undefined) { 
      this.http.get<any>(environment.base_url+'/get_testimonial/'+testimonial_id).subscribe(data => {
        if(data.success) {
          if(data.data.img != null) {
            this.author_img_url = environment.image_base_url+'/car_slider_images/'+data.data.id+'/'+data.data.img;
          }
          if(data.data.testimonial_translations.length > 0) {
            var title_languages_arr = [];
            data.data.testimonial_translations.forEach(translation => {
              let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], testimonial_section_id:[translation.testimonial_section_id, Validators.required], author_name:[translation.author_name, Validators.required], description:[translation.description, Validators.required]});
              title_languages_arr.push(title);
            });
            this.testimonial_form = this.fb.group({
              id: [testimonial_id],
              title: this.fb.array(title_languages_arr),
            })
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            this.toastr.error("Testimonial translations not found.", "")    
          }
        } 
        else {
          this.spinner.hide();
          this.toastr.error(data.msg.text, "")  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    else {
      var title_languages_arr = [];
      environment.languages_arr.forEach(translation => {
        let title = this.fb.group({language_code:[translation.code, Validators.required], language_id:[translation.id, Validators.required], testimonial_section_id:[1, Validators.required], author_name:['', Validators.required], description:['', Validators.required]});
        title_languages_arr.push(title);
      });
      this.testimonial_form = this.fb.group({
        id: [],
        title: this.fb.array(title_languages_arr),
      })
      this.spinner.hide();
    }
  }

  //  Function to return array of testimonial section title fields
  update_section_title() {
    this.http.get<any>(environment.base_url+'/get_title/testimonial').subscribe(data => {
      if(data.success) {
        if(data.data.testimonial_section_translations.length > 0) {
          var title_languages_arr = [];
          data.data.testimonial_section_translations.forEach(translation => {
            let title = this.fb.group({language_code:[translation.language_code, Validators.required], language_id:[translation.language_id, Validators.required], title:[translation.title, Validators.required]});
            title_languages_arr.push(title);
          });
          this.section_title_form = this.fb.group({
            id: [data.data.id],
            title: this.fb.array(title_languages_arr),
          })
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.toastr.error("Testimonial section translations not found.", "")    
        }
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to update testimonial section title
  save_section_title() {
    this.section_title_form_submitted = true
    if(this.section_title_form.invalid) {
      return 
    }
    this.http.post<any>(environment.base_url+'/save_testimonial_section_title', {
      title: this.section_title_form.get('title').value,
      id: this.section_title_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to upload author image
  upload_image(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Please select image file.", "");
      return
    }
    if(mimeType == 'image/jpeg' || mimeType == 'image/jpg' || mimeType == 'image/png') {
      if(this.fileData.size > 5000000) {
        this.toastr.error("Image file size should not be greater than 5MB.", "");  
        return;
      }
      const formData = new FormData();
      formData.append('file', this.fileData, this.testimonial_id+'.'+mimeType.substr(6));
      this.http.post<any>(environment.base_url+'/upload_author_image', formData).subscribe(data => {
        if(data.success) {
          //console.log(data)
          var reader = new FileReader();      
          reader.readAsDataURL(this.fileData); 
          reader.onload = (_event) => { 
            this.author_img_url = reader.result; 
          }
          //this.get_sliders(this.selected_language);
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")  
    }
    else {
      this.toastr.error("Image should be PNG|JPEG|JPG.", "");
      return
    }
  }

  //  Function to return array of testimonial fields
  get titleLanguagesArr() {
    return this.testimonial_form.get('title') as FormArray;
  }

  //  Function to return array of testimonial section title fields
  get testimonialSectionTitleArr() {
    return this.section_title_form.get('title') as FormArray;
  }
}
