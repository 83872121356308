<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Car List</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Cars</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row">

            <div class="col-lg-12">
                <div class="card user-profile-list">
                    <div class="card-body">
                        <div class="">
                            <div class="" *ngIf="cars_arr.length > 0;else no_car_found">
                                <ul class="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                    <li class="nav-item" *ngFor="let language of environment.languages;let language_index=index">
                                        <a class="nav-link" id="pills-{{language}}-tab" data-toggle="pill" href="#pills-{{language}}" role="tab" [class]="language_index == 0 ? 'active':''">{{language}}</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent1">
                                    <div class="tab-pane fade show" *ngFor="let language of environment.languages;let language_index=index" [class]="language_index == 0 ? 'active':''" id="pills-{{language}}" role="tabpanel">
                                        <div *ngIf="language_index == 0" class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Car Image</th>
														<th>Car Name</th>
														<th>Car Slug</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>  
                                                    </tr>
                                                </thead>
                                                <tbody  cdkDropList [cdkDropListData]="cars_arr"  (cdkDropListDropped)="drop($event)">
												    <tr class="drap-drop-box" *ngFor="let car of cars_arr" cdkDrag>
														<td>
															<img *ngIf="car.car_feature_image != null" [src]="image_base_url+'/car_feature_images/'+car.id+'/'+car.car_feature_image.img_name" width="100px">
														</td>
														<td>{{car.eng_title}}</td>
														<td>{{car.slug}}</td>
														<td>{{car.car_brands.eng_title}}</td>
														<td>{{car.car_models.eng_title}}</td>
														<td>
															<span class="badge badge-light-success">Active</span>
															<div class="overlay-edit">
																<a [routerLink]="[car.id]" type="button" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></a>
																<button type="button" (click)="delete_car(car.id);" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button>
															</div>
														</td>
													</tr>
											    </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Car Image</th>
                                                        <th>Car Name</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div *ngIf="language_index == 1" class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Car Image</th>
                                                        <th>Car Name</th>
														<th>Car Slug</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                 <tbody  cdkDropList [cdkDropListData]="cars_arr"  (cdkDropListDropped)="drop($event)">
                                                    <tr *ngFor="let car of cars_arr" cdkDrag>
                                                        <td>
                                                            <img *ngIf="car.car_feature_image != null" [src]="image_base_url+'/car_feature_images/'+car.id+'/'+car.car_feature_image.img_name" width="100px">
                                                        </td>
                                                        <td>{{car.spa_title}}</td>
														<td>{{car.slug}}</td>
                                                        <td>{{car.car_brands.spa_title}}</td>
                                                        <td>{{car.car_models.spa_title}}</td>
                                                        <td>
                                                            <span class="badge badge-light-success">Active</span>
                                                            <div class="overlay-edit">
                                                                <a [routerLink]="[car.id]" type="button" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></a>
                                                                <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Car Image</th>
                                                        <th>Car Name</th>
														<th>Car Slug</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div *ngIf="language_index == 2" class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Car Image</th>
                                                        <th>Car Name</th>
														<th>Car Slug</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody  cdkDropList [cdkDropListData]="cars_arr"  (cdkDropListDropped)="drop($event)">
                                                    <tr *ngFor="let car of cars_arr" cdkDrag>
                                                        <td>
                                                            <img *ngIf="car.car_feature_image != null" [src]="image_base_url+'/car_feature_images/'+car.id+'/'+car.car_feature_image.img_name" width="100px">
                                                        </td>
                                                        <td>{{car.ger_title}}</td>
														<td>{{car.slug}}</td>
                                                        <td>{{car.car_brands.ger_title}}</td>
                                                        <td>{{car.car_models.ger_title}}</td>
                                                        <td>
                                                            <span class="badge badge-light-success">Active</span>
                                                            <div class="overlay-edit">
                                                                <a [routerLink]="[car.id]" type="button" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></a>
                                                                <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Car Image</th>
                                                        <th>Car Name</th>
														<th>Car Slug</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div *ngIf="language_index == 3" class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Car Image</th>
                                                        <th>Car Name</th>
														<th>Car Slug</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody  cdkDropList [cdkDropListData]="cars_arr"  (cdkDropListDropped)="drop($event)">
                                                    <tr *ngFor="let car of cars_arr" cdkDrag>
                                                        <td>
                                                            <img *ngIf="car.car_feature_image != null" [src]="image_base_url+'/car_feature_images/'+car.id+'/'+car.car_feature_image.img_name" width="100px">
                                                        </td>
                                                        <td>{{car.rus_title}}</td>
														<td>{{car.slug}}</td>
                                                        <td>{{car.car_brands.rus_title}}</td>
                                                        <td>{{car.car_models.rus_title}}</td>
                                                        <td>
                                                            <span class="badge badge-light-success">Active</span>
                                                            <div class="overlay-edit">
                                                                <a [routerLink]="[car.id]" type="button" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></a>
                                                                <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Car Image</th>
                                                        <th>Car Name</th>
														<th>Car Slug</th>
                                                        <th>Car Brand</th>
                                                        <th>Car Model</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-template #no_car_found>
                                No car found.
                            </ng-template>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>