<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Investor Banner</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Investor Banner</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row">
            <div class="col-sm-12" *ngIf="environment.languages_arr.length > 0">
                <div class="card">
                    <form *ngIf="save_video_form" [formGroup]="save_video_form" (ngSubmit)="save_video()">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">YouTube Video URL</label>
                                                <input type="hidden" formControlName="id">
                                                <input formControlName="youtube_video_url" type="url" class="form-control" aria-describedby="emailHelp" placeholder="URL">
                                                <label *ngIf="submitted && get_errors.youtube_video_url.errors && get_errors.youtube_video_url.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <button type="submit" class="btn  btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card">
                    <form *ngIf="translation_form" [formGroup]="translation_form" (ngSubmit)="save_translation()">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <input type="hidden" formControlName="language_id">
                                                            <input type="hidden" formControlName="language_code">
                                                            <label for="exampleInputEmail1">Banner Heading</label>
                                                            <input type="text" class="form-control" formControlName="title1" aria-describedby="emailHelp" placeholder="Banner Heading">
                                                            <label *ngIf="translation_form_submitted && item.controls.title1.errors && item.controls.title1.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Banner Heading (Transparent)</label>
                                                            <input type="text" class="form-control" formControlName="title2" aria-describedby="emailHelp" placeholder="Banner Heading (Transparent)">
                                                            <label *ngIf="translation_form_submitted && item.controls.title2.errors && item.controls.title2.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Button Label</label>
                                                            <input type="text" class="form-control" formControlName="btn_title"  aria-describedby="emailHelp" placeholder="Button Label">
                                                            <label *ngIf="translation_form_submitted && item.controls.btn_title.errors && item.controls.btn_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Description</label>
                                                            <textarea type="text" name="doubtid" formControlName="description" class="form-control" id="doubtid" aria-describedby="emailHelp" placeholder="Heading"></textarea>
                                                            <label *ngIf="translation_form_submitted && item.controls.description.errors && item.controls.description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Button URL</label>
                                                            <input type="text" class="form-control"  aria-describedby="emailHelp" placeholder="Button URL">
                                                        </div>
                                                    </div> -->
                                                    <div class="col-md-1">
                                                        <button type="submit" class="btn  btn-primary">Save</button>
                                                    </div>
                                                    <!-- <div class="col-md-3">
                                                        <button type="submit" class="btn  btn-primary">Translate To All</button>
                                                    </div> -->
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Input group -->
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>