<div class="pcoded-main-container menus">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Main Menu</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Main Menu</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row">
            <div class="col-sm-12" id="update-menu" *ngIf="environment.languages_arr.length > 0">
                <div class="card">
                    <form *ngIf="update_menu_form" [formGroup]="update_menu_form" (ngSubmit)="onSubmit()">
                        <input type="hidden" formControlName="id">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Main Menu Name</label>
                                                        <input type="hidden" formControlName="language_id">
                                                        <input type="hidden" formControlName="language_code">
                                                        <input type="text" class="form-control" formControlName="title"  aria-describedby="emailHelp" placeholder="Main Menu Name">
                                                        <label *ngIf="submitted && item.controls.title.errors && item.controls.title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <button type="submit" class="btn  btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <div class="col-lg-12">
                <div class="card user-profile-list">

                    <div class="card-body">
                        <div class="">
                            <div class="" *ngIf="environment.languages_arr.length > 0">
                                <ul class="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                    <select class="form-control" style="width:200px" (change)="get_menus($event.target.value)">
                                        <option [value]="language.id" *ngFor="let language of environment.languages_arr">
                                            {{language.language}}
                                        </option>
                                    </select>
                                    <!-- <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                        <a class="nav-link" [ngClass]="{'active': language.id == 1}" id="pills-home-tab1" data-toggle="pill" href="#pills-home1" role="tab" aria-controls="pills-home1" aria-selected="true">{{language.language}}</a>
                                    </li> -->
                                </ul>
                                <div class="tab-content" id="pills-tabContent1" *ngIf="menus_arr.length > 0">
                                    <div class="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                        <div class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Menu Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="menus_arr.length > 0">
                                                    <tr *ngFor="let menu of menus_arr">
                                                        <td>{{menu.menu_translation.title}}</td>
                                                        <td>{{menu.menu_type}}</td>
                                                        <td>
                                                            <span class="badge badge-light-success">Active</span>
                                                            <div class="overlay-edit">
                                                                <button type="button" (click)="update_menu(menu.id)" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></button>
                                                                <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Menu Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>