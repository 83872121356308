import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-menus-list',
  templateUrl: './menus-list.component.html',
  styleUrls: ['./menus-list.component.css']
})
export class MenusListComponent implements OnInit {
  environment = environment;
  menus_arr = [];
  update_menu_form: FormGroup;
  submitted = false;
  selected_language_id;
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.get_menus(1);
  }


  // Function to get main menus list
  get_menus(language_id) {
    this.selected_language_id = language_id;
    this.http.get<any>(environment.base_url+'/get_menus/'+language_id).subscribe(data => {
      if(data.success) {
        this.menus_arr = data.data;
        this.spinner.hide();
        //console.log(this.menus_arr[0].menu_translation.title);  
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }


  // Function to return update main menu form errors
  get get_errors() { 
    return this.update_menu_form.controls 
  }
  
  // Function to update main menu
  onSubmit() {
    this.submitted = true
    if(this.update_menu_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/save_menu', {
      title: this.update_menu_form.get('title').value,
      id: this.update_menu_form.get('id').value,
    }).subscribe(data => {
      if(data.success) {
        this.get_menus(this.selected_language_id);
        var form:any = document.getElementById('update-menu');
        form.style.display = 'none';
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //  Function to return array of title fields
  get titleLanguagesArr() {
    return this.update_menu_form.get('title') as FormArray;
  }

  //  Function to return array of title fields
  update_menu(menu_id) {
    var form:any = document.getElementById('update-menu');
    form.style.display = 'block';
    this.spinner.show();
    this.http.get<any>(environment.base_url+'/get_menu/'+menu_id).subscribe(data => {
      if(data.success) {
        if(data.data.menu_translations.length > 0) {
          var title_languages_arr = [];
          data.data.menu_translations.forEach(trnslation => {
            let title = this.fb.group({language_code:[trnslation.language_code, Validators.required], language_id:[trnslation.language_id, Validators.required], title:[trnslation.title, Validators.required]});
            title_languages_arr.push(title);
          });
          this.update_menu_form = this.fb.group({
            id: [menu_id, Validators.required],
            title: this.fb.array(title_languages_arr),
          })
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.toastr.error("Menu translations not found.", "")    
        }
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

}
