<!-- [ Main Content ] start -->
<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Success Story Parallax</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#!">Success Story</a></li>
                            <li class="breadcrumb-item"><a href="#!">Success Story Parallax</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row" *ngIf="environment.languages_arr.length > 0">
            <div class="col-sm-12">
                <form *ngIf="story_form" [formGroup]="story_form" (ngSubmit)="save_story()">
                    <input type="hidden" formControlName="id">
                    <div class="card">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Main Title</label>
                                                            <input formControlName="title" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Main Title">
                                                            <label *ngIf="submitted && item.controls.title.errors && item.controls.title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Description</label>
                                                            <textarea type="text" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description"></textarea>
                                                            <label *ngIf="submitted && item.controls.description.errors && item.controls.description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Parallax Heading</label>
                                                            <input type="text" formControlName="parallax_title" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Parallax Heading">
                                                            <label *ngIf="submitted && item.controls.parallax_title.errors && item.controls.parallax_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Parallax Inverted Heading Text</label>
                                                            <input type="text" formControlName="parallax_inverted_title" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Parallax Inverted Heading Text">
                                                            <label *ngIf="submitted && item.controls.parallax_inverted_title.errors && item.controls.parallax_inverted_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Parallax Description</label>
                                                            <input type="text" formControlName="parallax_description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Parallax Description">
                                                            <label *ngIf="submitted && item.controls.parallax_description.errors && item.controls.parallax_description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Author Name</label>
                                                            <input type="text" formControlName="author_name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Author Name">
                                                            <label *ngIf="submitted && item.controls.author_name.errors && item.controls.author_name.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Parallax Button Name</label>
                                                            <input type="text" formControlName="btn_title" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Button Name">
                                                            <label *ngIf="submitted && item.controls.btn_title.errors && item.controls.btn_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Author Image</label>
                                                            <input type="file" (change)="upload_image($event, 'author_image')" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Upload Image">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="thumbnail mb-4">
                                                            <div class="thumb">
                                                                <img [src]="author_img_url" alt="" class="img-fluid img-thumbnail">
                                                                <!-- <a href="javscript:void();" class="actionbtn"><i class="feather icon-x-circle"></i></a> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Parallax Image</label>
                                                                <input type="file" (change)="upload_image($event, 'bg_img')" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Upload Image">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="thumbnail mb-4">
                                                            <div class="thumb">
                                                                <img [src]="paralax_img_url" alt="" class="img-fluid img-thumbnail">
                                                                <!-- <a href="javscript:void();" class="actionbtn"><i class="feather icon-x-circle"></i></a> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <button type="submit" class="btn  btn-primary">Save</button>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- Input group -->
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>