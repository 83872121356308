import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.css']
})
export class AddBrandComponent implements OnInit {
  submitted = false;
  add_brand_form_submitted = false;
  brands_list_arr:any = [];
  add_car_model_form: FormGroup;
  car_models_list_arr: any = [];
  add_car_brand_form: any = [];
  constructor(
    private http: HttpClient, 
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    // Calling function to get list of car brands and models 
    this.get_brands_list();

    this.add_car_model_form = this.formBuilder.group({
      id: [''],
      car_brand_id: ['', Validators.required],
      eng_title: ['', Validators.required],
      spa_title: ['', Validators.required],
      ger_title: ['', Validators.required],
      rus_title: ['', Validators.required],
    });

    this.add_car_brand_form = new FormGroup({
      id: new FormControl(''),
      eng_title: new FormControl('', [Validators.required]),
      spa_title: new FormControl('', [Validators.required]),
      ger_title: new FormControl('', [Validators.required]),
      rus_title: new FormControl('', [Validators.required]),
    }); 
  }

  //Function to return car brand form errors
  get get_errors() { 
    return this.add_car_brand_form.controls 
  }

  // Function to return car model form errors
  get add_model_form_errors() { 
    return this.add_car_model_form.controls 
  }

  // Function to get list of car brands and models 
  get_brands_list() {
    this.http.get<any>(environment.base_url+'/get_brands/0').subscribe(data => {
      if(data.success) {
        this.brands_list_arr = data.data.brands_list
        this.car_models_list_arr = data.data.car_models_list 
        this.spinner.hide();  
      } 
      else {
        for(var i in data.msg) {
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }


  // Function to submit/save car brand form data
  add_brand() {
    this.submitted = true
    if(this.add_car_brand_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/add_brand', {
      id: this.add_car_brand_form.get('id').value,
      eng_title: this.add_car_brand_form.get('eng_title').value,
      spa_title: this.add_car_brand_form.get('spa_title').value,
      ger_title: this.add_car_brand_form.get('ger_title').value,
      rus_title: this.add_car_brand_form.get('rus_title').value,
    }).subscribe(data => {
      if(data.success) {
        this.add_car_brand_form.reset()
        this.get_brands_list()
        
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")

     this.spinner.hide();
  }

  //Function to edit car brand
  edit_brand(car_brand_id) {
    this.spinner.show();
    this.http.get<any>(environment.base_url+'/get_car_brand/'+car_brand_id).subscribe(data => {
      if(data.success) {
        let car_brand_data = data.data
        this.add_car_brand_form = this.formBuilder.group({
          id: [car_brand_data.id],
          eng_title: [car_brand_data.eng_title, Validators.required],
          spa_title: [car_brand_data.spa_title, Validators.required],
          ger_title: [car_brand_data.ger_title, Validators.required],
          rus_title: [car_brand_data.rus_title, Validators.required],
        });  
        this.spinner.hide();
      } 
      else {
        for(var i in data.msg) {
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  // Function to submit/save car model form data
  add_model() {
    this.add_brand_form_submitted = true
    if(this.add_car_model_form.invalid) {
      return 
    }
    this.spinner.show();
    this.http.post<any>(environment.base_url+'/add_model', {
      car_brand_id: this.add_car_model_form.get('car_brand_id').value,
      id: this.add_car_model_form.get('id').value,
      eng_title: this.add_car_model_form.get('eng_title').value,
      spa_title: this.add_car_model_form.get('spa_title').value,
      ger_title: this.add_car_model_form.get('ger_title').value,
      rus_title: this.add_car_model_form.get('rus_title').value,
    }).subscribe(data => {
      if(data.success) {
        this.add_car_model_form.reset()
        this.get_brands_list();
        this.spinner.hide();
        this.toastr.success(data.msg.text, "");
      } 
      else {
        for(var i in data.msg) {
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to edit car model
  edit_model(car_model_id) {
    this.spinner.show();
    this.http.get<any>(environment.base_url+'/get_car_model/'+car_model_id).subscribe(data => {
      if(data.success) {
        let car_model_data = data.data
        this.add_car_model_form = this.formBuilder.group({
          id: [car_model_data.id],
          car_brand_id: [car_model_data.car_brand_id, Validators.required],
          eng_title: [car_model_data.eng_title, Validators.required],
          spa_title: [car_model_data.spa_title, Validators.required],
          ger_title: [car_model_data.ger_title, Validators.required],
          rus_title: [car_model_data.rus_title, Validators.required],
        }); 
        this.spinner.hide(); 
      } 
      else {
        for(var i in data.msg) {
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }
}
