<div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] start -->
            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5>Add Car Brand & Model</h5>
                            </div>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                                <li class="breadcrumb-item"><a>Add Car Brand & Model</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>Add Car Brand</h5>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <form [formGroup]="add_car_brand_form">
                                    <input type="hidden" formControlName="id">
                                    <div class="" style="padding: 25px 0;">
                                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">English</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Español</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Deutsche</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-russian-tab" data-toggle="pill" href="#pills-russian" role="tab" aria-controls="pills-russian" aria-selected="false">Pусский</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="pills-tabContent">
                                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group" style="padding:20px 0;">
                                                            <label for="exampleInputEmail1">Brand Name</label>
                                                            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Brand Name" formControlName="eng_title">
                                                            <label *ngIf="submitted && get_errors.eng_title.errors && get_errors.eng_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-md-2">
                                                        <button type="submit" class="btn  btn-primary">Save</button>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <button type="submit" class="btn  btn-primary">Translate To All</button>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group" style="padding:20px 0;">
                                                            <label for="exampleInputEmail1">Nombre De La Marca</label>
                                                            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="nombre de la marca" formControlName="spa_title">
                                                            <label *ngIf="submitted && get_errors.spa_title.errors && get_errors.spa_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-md-2">
                                                        <button type="submit" class="btn  btn-primary">Save</button>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group" style="padding:20px 0;">
                                                            <label for="exampleInputEmail1">Markenname</label>
                                                            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Markenname" formControlName="ger_title">
                                                            <label *ngIf="submitted && get_errors.ger_title.errors && get_errors.ger_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-russian" role="tabpanel" aria-labelledby="pills-russian-tab">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group" style="padding:20px 0;">
                                                            <label for="exampleInputEmail1">иИмя бренда</label>
                                                            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="имя бренда" formControlName="rus_title">
                                                            <label *ngIf="submitted && get_errors.rus_title.errors && get_errors.rus_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <button type="submit" (click)="add_brand()" class="btn  btn-primary">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- Input group -->

                    <div class="card user-profile-list">
                        <div class="card-header">
                            <h5>Car Brand List</h5>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <div class="" *ngIf="brands_list_arr.length > 0;else no_brand_found">
                                    <ul class="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="pills-home-tab1" data-toggle="pill" href="#pills-home1" role="tab" aria-controls="pills-home1" aria-selected="true">English</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-profile-tab1" data-toggle="pill" href="#pills-profile1" role="tab" aria-controls="pills-profile1" aria-selected="false">Español</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-contact-tab1" data-toggle="pill" href="#pills-contact1" role="tab" aria-controls="pills-contact1" aria-selected="false">Deutsche</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-rus-tab1" data-toggle="pill" href="#rus-contact1" role="tab" aria-controls="rus-contact1" aria-selected="false">Pусский</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent1">
                                        <div class="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Brand Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let brand of brands_list_arr">
                                                            <td>{{brand.eng_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" (click)="edit_brand(brand.id)" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></button>
                                                                    <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-profile1" role="tabpanel" aria-labelledby="pills-profile-tab1">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table1" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre De La Marca</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let brand of brands_list_arr">
                                                            <td>{{brand.spa_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" class="btn btn-icon btn-success"><i class="feather icon-check-circle"></i></button>
                                                                    <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact-tab1">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table2" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Markenname</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let brand of brands_list_arr">
                                                            <td>{{brand.ger_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" class="btn btn-icon btn-success"><i class="feather icon-check-circle"></i></button>
                                                                    <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="rus-contact1" role="tabpanel" aria-labelledby="pills-rus-tab1">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table2" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Имя бренда</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let brand of brands_list_arr">
                                                            <td>{{brand.rus_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" class="btn btn-icon btn-success"><i class="feather icon-check-circle"></i></button>
                                                                    <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #no_brand_found>
                                    No car brand found.
                                </ng-template>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>Add Car Model</h5>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <form [formGroup]="add_car_model_form">
                                    <input type="hidden" formControlName="id">
                                    <div class="">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Brand</label>
                                                    <select formControlName="car_brand_id" class="js-example-basic-single form-control">
                                                        <option value="">Select Brand</option>
                                                        <option [value]="brand.id" *ngFor="let brand of brands_list_arr">{{brand.eng_title}}</option>
                                                    </select>
                                                    <label *ngIf="add_brand_form_submitted && add_model_form_errors.car_brand_id.errors" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="nav nav-pills mb-3" id="pills-tab2" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="pills-home-tab2" data-toggle="pill" href="#pills-home2" role="tab" aria-controls="pills-home2" aria-selected="true">English</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-profile-tab2" data-toggle="pill" href="#pills-profile2" role="tab" aria-controls="pills-profile2" aria-selected="false">Español</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-contact-tab2" data-toggle="pill" href="#pills-contact2" role="tab" aria-controls="pills-contact2" aria-selected="false">Deutsche</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-rus-tab2" data-toggle="pill" href="#pills-rus2" role="tab" aria-controls="pills-rus2" aria-selected="false">Pусский</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="pills-tabContent2">
                                            <div class="tab-pane fade show active" id="pills-home2" role="tabpanel" aria-labelledby="pills-home-tab2">
                                                    <div class="row">
                                                        <!-- <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Brand</label>
                                                                <select formControlName="car_brand_id" class="js-example-basic-single form-control">
                                                                    <option value="brand.id" *ngFor="let brand of brands_list_arr">{{brand.eng_title}}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Model Name</label>
                                                                <input type="text" formControlName="eng_title" class="form-control" aria-describedby="emailHelp" placeholder="Model Name">
                                                                <label *ngIf="add_brand_form_submitted && add_model_form_errors.eng_title.errors && get_errors.eng_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <div class="col-md-2">
                                                            <button type="submit" class="btn  btn-primary">Save</button>
                                                        </div>
                                                    </div> -->
                                            </div>
                                            <div class="tab-pane fade" id="pills-profile2" role="tabpanel" aria-labelledby="pills-profile-tab2">
                                                    <div class="row">
                                                        <!-- <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Marca</label>
                                                                <select formControlName="car_brand_id" class="js-example-basic-single form-control">
                                                                    <option value="AL" *ngFor="let brand of brands_list_arr">{{brand.spa_title}}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Nombre Del Modelo</label>
                                                                <input type="text" formControlName="spa_title" class="form-control"  aria-describedby="emailHelp" placeholder="Nombre Del Modelo">
                                                                <label *ngIf="add_brand_form_submitted && add_model_form_errors.spa_title.errors && get_errors.spa_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">este campo es requerido</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <div class="col-md-2">
                                                            <button type="submit" class="btn  btn-primary">Save</button>
                                                        </div>
                                                    </div> -->
                                            </div>
                                            <div class="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact-tab2">
                                                    <div class="row">
                                                        <!-- <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Marke</label>
                                                                <select formControlName="car_brand_id" class="js-example-basic-single form-control">
                                                                    <option value="AL" *ngFor="let brand of brands_list_arr">{{brand.ger_title}}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Modellname</label>
                                                                <input type="text" class="form-control" formControlName="ger_title"  aria-describedby="emailHelp" placeholder="Modellname">
                                                                <label *ngIf="add_brand_form_submitted && add_model_form_errors.ger_title.errors && get_errors.ger_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">Dieses Feld wird benötigt</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <div class="col-md-2">
                                                            <button type="submit" class="btn  btn-primary">Save</button>
                                                        </div>
                                                    </div> -->
                                            </div>
                                            <div class="tab-pane fade" id="pills-rus2" role="tabpanel" aria-labelledby="pills-rus-tab2">
                                                <div class="row">
                                                    <!-- <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Марка</label>
                                                            <select formControlName="car_brand_id" class="js-example-basic-single form-control">
                                                                <option value="{{brand.id}}" *ngFor="let brand of brands_list_arr">{{brand.rus_title}}</option>
                                                            </select>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Название модели</label>
                                                            <input type="text" formControlName="rus_title" class="form-control"  aria-describedby="emailHelp" placeholder="Название модели">
                                                            <label *ngIf="add_brand_form_submitted && add_model_form_errors.rus_title.errors && get_errors.rus_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">Это поле обязательно к заполнению</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-md-2">
                                                        <button type="submit" class="btn  btn-primary">Save</button>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <button (click)="add_model()" type="submit" [disabled]="brands_list_arr.length<1" class="btn  btn-primary">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- Input group -->

                    <div class="card user-profile-list">
                        <div class="card-header">
                            <h5>Car Model List</h5>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <div class="" *ngIf="car_models_list_arr.length > 0;else no_car_model_found">
                                    <ul class="nav nav-pills mb-3" id="pills-tab3" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="pills-home-tab3" data-toggle="pill" href="#pills-home3" role="tab" aria-controls="pills-home3" aria-selected="true">English</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-profile-tab3" data-toggle="pill" href="#pills-profile3" role="tab" aria-controls="pills-profile3" aria-selected="false">Español</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-contact-tab3" data-toggle="pill" href="#pills-contact3" role="tab" aria-controls="pills-contact3" aria-selected="false">Deutsche</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-rus-tab3" data-toggle="pill" href="#rus-contact3" role="tab" aria-controls="rus-contact3" aria-selected="false">Pусский</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent3">
                                        <div class="tab-pane fade show active" id="pills-home3" role="tabpanel" aria-labelledby="pills-home-tab3">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table3" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Brand Name</th>
                                                            <th>Model Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let car_model of car_models_list_arr">
                                                            <td>{{car_model.car_brands.eng_title}}</td>
                                                            <td>{{car_model.eng_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" class="btn btn-icon btn-success" (click)="edit_model(car_model.id)"><i class="feather icon-edit"></i></button>
                                                                    <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-profile3" role="tabpanel" aria-labelledby="pills-profile-tab3">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table4" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre De La Marca</th>
                                                            <th>Model Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let car_model of car_models_list_arr">
                                                            <td>{{car_model.car_brands.spa_title}}</td>
                                                            <td>{{car_model.spa_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" class="btn btn-icon btn-success" (click)="edit_model(car_model.id)"><i class="feather icon-edit"></i></button>
                                                                    <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-contact3" role="tabpanel" aria-labelledby="pills-contact-tab3">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table5" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Markenname</th>
                                                            <th>Model Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let car_model of car_models_list_arr">
                                                            <td>{{car_model.car_brands.ger_title}}</td>
                                                            <td>{{car_model.ger_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" class="btn btn-icon btn-success" (click)="edit_model(car_model.id)"><i class="feather icon-edit"></i></button>
                                                                    <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="rus-contact3" role="tabpanel" aria-labelledby="pills-rus-tab3">
                                            <div class="dt-responsive table-responsive">
                                                <table id="user-list-table5" class="table nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Имя бренда</th>
                                                            <th>Model Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let car_model of car_models_list_arr">
                                                            <td>{{car_model.car_brands.rus_title}}</td>
                                                            <td>{{car_model.rus_title}}</td>
                                                            <td>
                                                                <span class="badge badge-light-success">Active</span>
                                                                <div class="overlay-edit">
                                                                    <button type="button" class="btn btn-icon btn-success" (click)="edit_model(car_model.id)"><i class="feather icon-edit"></i></button>
                                                                    <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #no_car_model_found>
                                    No car model found
                                </ng-template>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- [ Main Content ] end -->
        </div>
    </div>