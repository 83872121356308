import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  login_form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  constructor(
    private http: HttpClient, 
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  get get_errors() { 
    return this.login_form.controls 
  }
  
  login() {

    this.submitted = true;
    if(this.login_form.invalid) {
        return;
    }
    this.http.post<any>(environment.base_url+'/login', {
      email: this.login_form.get('email').value,
      password: this.login_form.get('password').value,
    }).subscribe(data => {
      this.spinner.hide();
      if(data.success) {
          localStorage.setItem('userDetails', JSON.stringify(data));
          this.toastr.success(data.msg.text, "");
          this.router.navigateByUrl('/add_brand');  
      } 
      else {

        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

}
