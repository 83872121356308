import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  environment = environment;
  selected_language = 1;
  blogs_arr = [];
  thumbnail_url:any = '';
  img_url:any = '';
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.get_blogs(this.selected_language);
  }

  get_blogs(selected_language) {
    this.selected_language = selected_language;
    this.http.get<any>(environment.base_url+'/get_blogs/'+selected_language).subscribe(data => {
      if(data.success) {
        this.blogs_arr = data.data;
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }

  //Function to delete blog
  delete_blog(blog_id) {
    if(confirm('Are you sure to delete this category?0')) {
      this.http.post<any>(environment.base_url+'/delete_blog', {
        id: blog_id,
      }).subscribe(data => {
        if(data.success) {
          this.get_blogs(this.selected_language);
          this.spinner.hide();
          this.toastr.success(data.msg.text, "");
        } 
        else {
          for(var i in data.msg) {
            this.spinner.hide();
            this.toastr.error(data.msg[i], "");
          }  
        }
      }),
      (error) => this.toastr.error(error.message, "")
    }
    
  }

}
