import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { CarsListComponent } from './cars/cars-list/cars-list.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { AddCarComponent } from './cars/add-car/add-car.component';
import { AddBrandComponent } from './cars/add-brand/add-brand.component';
import { MenusListComponent } from './menus/menus/menus-list/menus-list.component';
import { SubMenusListComponent } from './menus/sub_menus/sub-menus-list/sub-menus-list.component';
import { SliderComponent } from './home/slider/slider.component';
import { WhyUsComponent } from './home/why-us/why-us.component';
import { TestimonialsComponent } from './testimonials/testimonials/testimonials.component';
import { AddTestimonialComponent } from './testimonials/add-testimonial/add-testimonial.component';
import { ParallaxComponent } from './home/parallax/parallax.component';
import { LogosComponent } from './logos/logos.component';
import { BannerComponent } from './investor/banner/banner.component';
import { BlogsComponent } from './blogs/blogs/blogs.component';
import { AddBlogComponent } from './blogs/add-blog/add-blog.component';
import { BlogCategoriesComponent } from './blogs/blog-categories/blog-categories.component';
import { SuccessStoryComponent } from './success-story/success-story.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'cars', canActivate:[AuthGuardService], component: CarsListComponent },
  { path: 'cars/:id', canActivate:[AuthGuardService], component: AddCarComponent },
  { path: 'add_car', canActivate:[AuthGuardService], component: AddCarComponent },
  { path: 'add_brand', canActivate:[AuthGuardService], component: AddBrandComponent },
  { path: 'menus', canActivate:[AuthGuardService], component: MenusListComponent },
  { path: 'sub_menus', canActivate:[AuthGuardService], component: SubMenusListComponent },
  { path: 'slider', canActivate:[AuthGuardService], component: SliderComponent },
  { path: 'why_us', canActivate:[AuthGuardService], component: WhyUsComponent },
  { path: 'testimonials', canActivate:[AuthGuardService], component: TestimonialsComponent },
  { path: 'add_testimonial', canActivate:[AuthGuardService], component: AddTestimonialComponent },
  { path: 'update_testimonial/:id', canActivate:[AuthGuardService], component: AddTestimonialComponent },
  { path: 'parallax', canActivate:[AuthGuardService], component: ParallaxComponent },
  { path: 'logos', canActivate:[AuthGuardService], component: LogosComponent },
  { path: 'investor/banner', canActivate:[AuthGuardService], component: BannerComponent },
  { path: 'blogs', canActivate:[AuthGuardService], component: BlogsComponent },
  { path: 'add_blog', canActivate:[AuthGuardService], component: AddBlogComponent },
  { path: 'update_blog/:id', canActivate:[AuthGuardService], component: AddBlogComponent },
  { path: 'blog_categories', canActivate:[AuthGuardService], component: BlogCategoriesComponent },
  { path: 'success_story', canActivate:[AuthGuardService], component: SuccessStoryComponent },
  
  
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes) 
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
