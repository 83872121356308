<!-- [ Main Content ] start -->
<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Slider</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Slider</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row">
            <div class="col-sm-12" *ngIf="environment.languages_arr.length > 0">
                <div class="card">
                    <form *ngIf="add_slider_form" [formGroup]="add_slider_form" (ngSubmit)="onSubmit()">
                        <input type="hidden" formControlName="id">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="hidden" formControlName="language_id">
                                                        <input type="hidden" formControlName="language_code">
                                                        <label for="exampleInputEmail1">Slider Heading 1</label>
                                                        <input type="text" class="form-control" formControlName="title1" add_slider_form aria-describedby="emailHelp" placeholder="Slider Heading 1">
                                                        <label *ngIf="submitted && item.controls.title1.errors && item.controls.title1.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Slider Heading 2</label>
                                                        <input type="text" class="form-control" formControlName="title2" aria-describedby="emailHelp" placeholder="Slider Heading 2">
                                                        <label *ngIf="submitted && item.controls.title2.errors && item.controls.title2.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Slider Button Link</label>
                                                        <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Slider Button Link">
                                                    </div>
                                                </div> -->
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Slider Description</label>
                                                        <textarea class="form-control"  formControlName="description"  placeholder="Slider Description"></textarea>
                                                        <label *ngIf="submitted && item.controls.description.errors && item.controls.description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3" *ngIf="slider_img_id != ''">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Slider Image</label>
                                                    <div class="input-group cust-file-button">
                                                        <div class="custom-file">
                                                            <input type="file" (change)="upload_slider_image($event)" class="custom-file-input" id="inputGroupFile04">
                                                            <label class="custom-file-label" for="inputGroupFile04">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" *ngIf="slider_img_id != ''">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">&nbsp;</label>
                                                    <div class="input-group cust-file-button">
                                                        <div class="custom-file">
                                                            <img [src]="slider_img_url" width="100px">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <button type="submit" class="btn  btn-primary">Save</button>
                                            </div>
                                            <!-- <div class="col-md-3">
                                                <button type="submit" class="btn  btn-primary">Translate To All</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Input group -->
            </div>
            <div class="col-lg-12">
                <div class="card user-profile-list">
                    <div class="card-body">
                        <div class="">
                            <div class="" *ngIf="environment.languages_arr.length > 0">
                                <ul class="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                    <select class="form-control" style="width:200px" (change)="get_sliders($event.target.value)">
                                        <option [value]="language.id" *ngFor="let language of environment.languages_arr">
                                            {{language.language}}
                                        </option>
                                    </select>
                                </ul>
                                <div class="tab-content" id="pills-tabContent1">
                                    <div class="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                        <div class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Slider Heading 1</th>
                                                        <th>Slider Heading 2</th>
                                                        <th>Slider Description</th>
                                                        <th>Slider Image</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="sliders.length > 0">
                                                    <tr *ngFor="let slider of sliders">
                                                        <td>{{slider.title1}}</td>
                                                        <td>{{slider.title2}}</td>
                                                        <td [innerHTML]="slider.description">{{slider.description}}</td>
                                                        <td><img *ngIf="slider.slider_image.img != null" [src]="environment.image_base_url+'/car_slider_images/'+slider.slider_image.id+'/'+slider.slider_image.img" width="100px"></td>
                                                        <td>
                                                            <span class="badge badge-light-success">Active</span>
                                                            <div class="overlay-edit">
                                                                <button type="button" (click)="add_slider(slider.slider_image_id)" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></button>
                                                                <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Slider Heading 1</th>
                                                        <th>Slider Heading 2</th>
                                                        <th>Slider Description</th>
                                                        <th>Slider Image</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>