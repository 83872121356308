<div class="pcoded-main-container">
    <div class="pcoded-content">
        <!-- [ breadcrumb ] start -->
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5>Why US</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a><i class="feather icon-home"></i></a></li>
                            <li class="breadcrumb-item"><a>Why us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ breadcrumb ] end -->
        <!-- [ Main Content ] start -->
        <div class="row" *ngIf="environment.languages_arr.length > 0">
            <div class="col-sm-12">
                <div class="card">
                    <form *ngIf="why_us_section_form" [formGroup]="why_us_section_form" (ngSubmit)="save_why_us_section()">
                        <input type="hidden" formControlName="id">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-section-tab-{{language.id}}" data-toggle="pill" href="#pills-section-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of whyUsSectionTitleArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-section-{{item.value.language_id}}" role="tabpanel">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <input type="hidden" formControlName="language_id">
                                                            <input type="hidden" formControlName="language_code">
                                                            <label for="exampleInputEmail1">Title</label>
                                                            <input type="text" formControlName="title" class="form-control"  aria-describedby="emailHelp" placeholder="Title">
                                                            <label *ngIf="why_us_section_form_submitted && item.controls.title.errors && item.controls.title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <button type="submit" class="btn  btn-primary">Save</button>
                                                    </div>
                                                    <!-- <div class="col-md-4">
                                                        <button type="submit" class="btn  btn-primary">Translate To All</button>
                                                    </div> -->
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card">
                    <form *ngIf="why_us_form" [formGroup]="why_us_form" (ngSubmit)="onSubmit()">
                        <input type="hidden" formControlName="id">
                        <div class="card-body">
                            <div class="">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let language of environment.languages_arr">
                                            <a [ngClass]="{'active': language.id == 1}" class="nav-link active" id="pills-home-tab-{{language.id}}" data-toggle="pill" href="#pills-home-{{language.id}}" role="tab"  aria-selected="true">
                                                {{language.language}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent" formArrayName="title">
                                        <div class="tab-pane fade show" [class]="{'active': item.value.language_id == 1}" *ngFor="let item of titleLanguagesArr.controls; let pointIndex=index" [formGroupName]="pointIndex" id="pills-home-{{item.value.language_id}}" role="tabpanel">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <input type="hidden" formControlName="language_id">
                                                            <input type="hidden" formControlName="why_us_section_id">
                                                            <input type="hidden" formControlName="language_code">
                                                            <label for="exampleInputEmail1">Sub Title</label>
                                                            <input type="text" class="form-control" formControlName="sub_title"  aria-describedby="emailHelp" placeholder="Sub Title">
                                                            <label *ngIf="submitted && item.controls.sub_title.errors && item.controls.sub_title.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Description</label>
                                                            <textarea class="form-control" formControlName="description" placeholder="Sub Description"></textarea>
                                                            <label *ngIf="submitted && item.controls.description.errors && item.controls.description.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <button type="submit" class="btn  btn-primary">Save</button>
                                                    </div>
                                                    <!-- <div class="col-md-4">
                                                        <button type="submit" class="btn  btn-primary">Translate To All</button>
                                                    </div> -->
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Input group -->
            <div class="col-lg-12">
                <div class="card user-profile-list">
                    <div class="card-body">
                        <div class="">
                            <div class="" *ngIf="why_us_arr.length > 0">
                                <ul class="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                    <select class="form-control" style="width:200px" (change)="get_why_us_btns($event.target.value)">
                                        <option [value]="language.id" *ngFor="let language of environment.languages_arr">
                                            {{language.language}}
                                        </option>
                                    </select>
                                </ul>
                                <div class="tab-content" id="pills-tabContent1">
                                    <div class="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                        <div class="dt-responsive table-responsive">
                                            <table id="user-list-table" class="table nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Sub Title</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let why_us of why_us_arr">
                                                        <td>{{why_us.sub_title}}</td>
                                                        <td>{{why_us.description}}</td>
                                                        <td>
                                                            <span class="badge badge-light-success">Active</span>
                                                            <div class="overlay-edit">
                                                                <button type="button" (click)="add_why_us(why_us.why_us_button_id)" class="btn btn-icon btn-success"><i class="feather icon-edit"></i></button>
                                                                <!-- <button type="button" class="btn btn-icon btn-danger"><i class="feather icon-trash-2"></i></button> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Sub Title</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- [ Main Content ] end -->
    </div>
</div>